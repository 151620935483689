import { Button, Collapse, Stack, Tooltip } from "@mui/material";
import { Dispatch, SetStateAction } from "react";
import { CustomSpinner } from "../CustomSpinner";
import {
  SimpleSelectOption,
  useActionOptions,
  useMatchOptions,
  useMatchTypeOptions,
  useTagOptions,
  useTeamOptions,
} from "../simple-select/simpleSelectOptions";
import { InnerMarginBox } from "../InnerMarginBox";
import { AdvancedFilters } from "./AdvancedFilters";
import { SingleSelect } from "../simple-select/SimpleSelect";
import { ChipSelect } from "../simple-select/ChipSelect";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { getFilteredEventFilters } from "../../requests/sendGetMatchDetailedEvents";
import { Dayjs } from "dayjs";
import { useNavigate } from "react-router-dom";
import { useGetMatch } from "../../requests/sendGetMatches";

export type EventFilters = {
  matchId: string;
  team1Id: string;
  team2Id: string;
  possessionTeamId: string;
  matchTypeId: string;
  tags: string[];
  previousActionIds: string[];
  actionIds: string[];
  nextActionIds: string[];
  commentRegex: string;
  startDate: Dayjs | null;
  endDate: Dayjs | null;
  pitchXMin: string;
  pitchXMax: string;
  pitchYMin: string;
  pitchYMax: string;
};

type Props = {
  isAdvancedFilters: boolean;
  setIsAdvancedFilters: Dispatch<SetStateAction<boolean>>;
  eventFilters: EventFilters;
  setEventFilters: Dispatch<SetStateAction<EventFilters>>;
  setIsSearchEditMode: Dispatch<SetStateAction<boolean>>;
};

export const VideoReviewForm = ({
  isAdvancedFilters,
  setIsAdvancedFilters,
  eventFilters,
  setEventFilters: _setEventFilters,
  setIsSearchEditMode,
}: Props) => {
  const navigate = useNavigate();

  const getMatch = useGetMatch();

  const teamOptions = useTeamOptions();
  const matchOptions = useMatchOptions();
  const matchTypeOptions = useMatchTypeOptions();
  const tagOptions = useTagOptions();
  const actionOptions = useActionOptions();

  const setEventFilters = (updatedFilters: Partial<EventFilters>): void =>
    _setEventFilters((prevState) => ({
      ...prevState,
      ...updatedFilters,
    }));

  const setEventFilter = (
    key: keyof EventFilters,
    previousIds: (number | string)[],
    options: SimpleSelectOption[],
    getValues: (prevState: SimpleSelectOption[]) => SimpleSelectOption[]
  ) =>
    setEventFilters({
      [key]: getValues(
        previousIds.map(
          (actionId) =>
            options.find(
              (option) => actionId === option.id
            ) as SimpleSelectOption
        )
      ).map((option) => option.id),
    });

  if (
    !teamOptions ||
    !matchOptions ||
    !matchTypeOptions ||
    !tagOptions ||
    !actionOptions
  ) {
    return <CustomSpinner />;
  }

  const possessionOptions =
    eventFilters.team1Id && eventFilters.team2Id
      ? teamOptions.filter((option) =>
          [eventFilters.team1Id, eventFilters.team2Id].includes(option.id)
        )
      : teamOptions;

  const SearchButton = () => {
    const disabled = getFilteredEventFilters(eventFilters).length === 0;
    return (
      <Tooltip
        // Zero-length string will cause the tooltip to be disabled.
        title={disabled ? "At least one filter must be chosen." : ""}
        enterDelay={0}
        followCursor={true}
      >
        <span>
          <Button
            onClick={() => {
              const searchParams = new URLSearchParams();
              getFilteredEventFilters(eventFilters).forEach(([key, value]) =>
                searchParams.append(key, value)
              );
              navigate(`/video-review?${searchParams.toString()}`);
              setIsSearchEditMode(false);
            }}
            variant="contained"
            sx={{ mx: 2 }}
            disabled={disabled}
          >
            Search
          </Button>
        </span>
      </Tooltip>
    );
  };

  return (
    <InnerMarginBox>
      <div style={{ marginLeft: "0.5em" }}>
        <Stack sx={{ m: 0 }}>
          <span>
            <SingleSelect
              value={eventFilters.matchId}
              setValue={(value) => {
                // If the match is selected, set team1 and team2 to the selected match's teams.
                if (value.length === 0) {
                  setEventFilters({
                    matchId: value,
                    team1Id: "",
                    team2Id: "",
                  });
                } else {
                  const match = getMatch(parseInt(value));
                  const team1Id = match?.home_team_id.toString() ?? "";
                  const team2Id = match?.away_team_id.toString() ?? "";
                  const possessionTeamId =
                    eventFilters.possessionTeamId === team1Id ||
                    eventFilters.possessionTeamId === team2Id
                      ? eventFilters.possessionTeamId
                      : "";
                  setEventFilters({
                    matchId: value,
                    team1Id,
                    team2Id,
                    possessionTeamId,
                  });
                }

                // If the match is unset, reset team1 and team2 if both chosen.
              }}
              label="Match"
              options={matchOptions}
              none
              widthFactor={2.5}
            />
            <SingleSelect
              value={eventFilters.possessionTeamId}
              setValue={(value) => setEventFilters({ possessionTeamId: value })}
              label="Possession"
              options={possessionOptions}
              none
              widthFactor={2.5}
            />
          </span>
          <span>
            <ChipSelect
              label="Actions"
              widthFactor={2.5}
              options={actionOptions}
              values={eventFilters.actionIds.map(
                (actionId) =>
                  actionOptions.find(
                    (actionOption) => actionId === actionOption.id
                  ) as SimpleSelectOption
              )}
              setValues={(
                _getValues: (
                  prevState: SimpleSelectOption[]
                ) => SimpleSelectOption[]
              ) =>
                setEventFilter(
                  "actionIds",
                  eventFilters.actionIds,
                  actionOptions,
                  _getValues
                )
              }
            />
            <ChipSelect
              label="Tags"
              widthFactor={2.5}
              options={tagOptions}
              values={eventFilters.tags?.map(
                (tag) =>
                  tagOptions.find(
                    (tagOption) => tag === tagOption.id
                  ) as SimpleSelectOption
              )}
              setValues={(
                _getValues: (
                  prevState: SimpleSelectOption[]
                ) => SimpleSelectOption[]
              ) =>
                setEventFilter(
                  "tags",
                  eventFilters.tags,
                  tagOptions,
                  _getValues
                )
              }
            />
          </span>
        </Stack>
      </div>
      <Button
        sx={{ mx: 2 }}
        onClick={() => setIsAdvancedFilters(!isAdvancedFilters)}
      >
        Advanced Options
        {isAdvancedFilters ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </Button>
      <Collapse in={isAdvancedFilters}>
        <br />
        <AdvancedFilters
          eventFilters={eventFilters}
          setEventFilter={setEventFilter}
          setEventFilters={setEventFilters}
        />
      </Collapse>
      <SearchButton />
    </InnerMarginBox>
  );
};
