import React, { ReactElement } from "react";
import {
  IconButton,
  IconButtonProps,
  SvgIconProps,
  Tooltip,
  TooltipProps,
} from "@mui/material";
import { UserPermission } from "../requests/utils/databaseTypes";
import { usePermissionLockAndMessage } from "../hooks/usePermissionLockAndMessage";

type Props = Omit<IconButtonProps, "disabled"> & {
  lockMessage: string;
  isLocked: boolean;
  minUserPermission?: UserPermission;
  tooltipProps?: Partial<TooltipProps>;
  children: ReactElement<SvgIconProps>;
};

export const LockableIconButton = ({
  lockMessage,
  isLocked,
  minUserPermission,
  tooltipProps,
  children,
  ...iconButtonProps
}: Props) => {
  const { permissionMessage, permissionLock } = usePermissionLockAndMessage(
    lockMessage,
    isLocked,
    minUserPermission
  );

  const button = (
    <IconButton {...iconButtonProps} disabled={isLocked || permissionLock}>
      {children}
    </IconButton>
  );

  return !permissionLock ? (
    button
  ) : (
    <Tooltip
      title={permissionMessage}
      followCursor
      enterDelay={500}
      {...tooltipProps}
    >
      {/*<Badge
        badgeContent={<LockOutlinedIcon sx={{ fontSize: "1.3em" }} />}
        anchorOrigin={{ vertical: "top", horizontal: "left" }}
        sx={{
          "& .MuiBadge-badge": {
            top: 9, // Adjust this value to fine-tune the vertical position
            left: 11, // Adjust this value to fine-tune the horizontal position
          },
        }}
      >*/}
      {button}
      {/*</Badge>*/}
    </Tooltip>
  );
};
