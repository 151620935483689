import { MutableGridCol } from "./MutableGridCol";
import { GridValidRowModel } from "@mui/x-data-grid/models/gridRows";
import {
  GridValueFormatterParams,
  GridValueSetterParams,
  ValueOptions,
} from "@mui/x-data-grid";
import { Dispatch, SetStateAction } from "react";

export class NewSelectorGridCol<
  Item extends GridValidRowModel
> extends MutableGridCol<Item> {
  type: string = "singleSelect";
  valueOptions: ValueOptions[];
  valueFormatter: (params: GridValueFormatterParams) => string;

  constructor(
    field: Extract<keyof Item, string>,
    headerName: string,
    width: number,
    valueOptions: ValueOptions[],
    valueFormatter: (params: GridValueFormatterParams) => string
  ) {
    super(field, headerName, width);
    this.valueOptions = valueOptions;
    this.valueFormatter = valueFormatter;
  }
}

export class SelectorGridCol<
  Item extends GridValidRowModel
> extends MutableGridCol<Item> {
  type: string = "singleSelect";
  valueOptions: string[];
  valueGetter: (params: GridValueSetterParams<Item>) => string;
  valueSetter: (params: GridValueSetterParams<Item>) => Item;

  constructor(
    field: Extract<keyof Item, string>,
    headerName: string,
    width: number,
    valueOptions: string[],
    convertOptionToId: (value: string) => number | "",
    valueGetter: (params: GridValueSetterParams<Item>) => string,
    rowEdits: { [p: string]: Item },
    setRowEdits: Dispatch<SetStateAction<{ [p: string]: Item }>>
  ) {
    super(field, headerName, width);
    this.valueOptions = valueOptions;
    this.valueGetter = valueGetter;
    this.valueSetter = (params: GridValueSetterParams<Item>) => {
      if (params.value === undefined) {
        return params.row;
      }

      // TODO: use valueParser instead of convertOptionToId
      const value = convertOptionToId(params.value) ?? 0;
      const currentValue = rowEdits?.[params.row.id]?.[field];
      // return {...params.row, [field]: value};
      // This check is necessary to prevent a continuous state refresh.
      if (currentValue && currentValue !== value) {
        // TODO: React doesn't like us updating another component in here
        setRowEdits((prevState) => {
          const newState = { ...prevState };
          // @ts-ignore TODO: not sure why, but error `TS2322: Type number is not assignable to type Item[keyof Item]`
          newState[params.row.id][field] = value;
          return newState;
        });
      }
      return {
        ...params.row,
        [field]: value,
      };
    };
  }
}

export class ListSelectorGridCol<
  Item extends GridValidRowModel
> extends MutableGridCol<Item> {
  valueGetter: (params: GridValueSetterParams<Item>) => string;
  valueSetter: (params: GridValueSetterParams<Item>) => Item;

  constructor(
    field: Extract<keyof Item, string>,
    headerName: string,
    width: number,
    valueGetter: (params: GridValueSetterParams<Item>) => string,
    rowEdits: { [p: string]: Item },
    setRowEdits: Dispatch<SetStateAction<{ [p: string]: Item }>>
  ) {
    super(field, headerName, width);
    this.valueGetter = valueGetter;
    this.valueSetter = (params: GridValueSetterParams<Item>) => {
      if (params.value === undefined) {
        return params.row;
      }

      const value = params.value;
      // This check is necessary to prevent a continuous state refresh.
      if (rowEdits?.[params.row.id]?.[field] !== value) {
        // TODO: React doesn't like us updating another component in here
        setRowEdits((prevState) => {
          const newState = { ...prevState };
          newState[params.row.id][field] = value;
          return newState;
        });
      }
      return {
        ...params.row,
        [field]: value,
      };
    };
  }
}
