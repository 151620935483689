import {
  Autocomplete,
  AutocompleteChangeReason,
  FormControl,
  TextField,
} from "@mui/material";
import { TooltipChip } from "../TooltipChip";
import React, { SyntheticEvent } from "react";
import { SimpleSelectOption } from "./simpleSelectOptions";
import assert from "assert";

type Props = {
  maxVisibleLength?: number;
  options: SimpleSelectOption[];
  values: SimpleSelectOption[];
  // setValues: (selected: SimpleSelectOption[]) => void;
  setValues: (
    getValues: (prevState: SimpleSelectOption[]) => SimpleSelectOption[]
  ) => void;
  label: string;
  widthFactor?: number;
};

export const ChipSelect = ({
  options,
  maxVisibleLength = 12,
  values,
  setValues,
  label,
  widthFactor = 1,
}: Props) => {
  const width = 9 * widthFactor + "em";

  const onChange = (
    event: SyntheticEvent,
    values:
      | string
      | SimpleSelectOption
      | readonly (string | SimpleSelectOption)[]
      | null,
    reason: AutocompleteChangeReason
  ): void => {
    if (reason === "blur") {
      return;
    }
    assert(reason === "selectOption");
    setValues(() => values as SimpleSelectOption[]);
  };

  const onDelete = (option: SimpleSelectOption): void => {
    setValues((prevState) =>
      prevState.filter((value) => value.id !== option.id)
    );
  };

  return (
    <FormControl
      sx={{
        m: 1,
        width,
      }}
    >
      <Autocomplete<SimpleSelectOption, boolean, boolean, boolean>
        sx={{ width }}
        ListboxProps={{ style: { fontSize: ".75em" } }}
        options={options}
        getOptionLabel={(option) => {
          // noinspection SuspiciousTypeOfGuard
          assert(typeof option !== "string");
          return option.value;
        }}
        multiple
        value={values}
        autoSelect
        clearOnBlur
        disableClearable
        filterSelectedOptions
        includeInputInList
        isOptionEqualToValue={(option, value) => option.id === value.id}
        renderTags={(values) => {
          return (
            <div style={{ overflow: "auto", maxHeight: "3.5em" }}>
              {values?.map((value) => (
                <TooltipChip
                  key={value.id}
                  value={value.value}
                  maxVisibleLength={maxVisibleLength}
                  onDelete={() => onDelete(value)}
                />
              ))}
            </div>
          );
        }}
        onChange={onChange}
        renderInput={(params) => (
          <TextField
            label={label}
            {...params}
            hiddenLabel
            InputProps={{
              ...params.InputProps,
              style: { fontSize: "1em" },
            }}
          />
        )}
      />
    </FormControl>
  );
};
