import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from "@tanstack/react-query";
import { sendDeleteMatchEvents } from "./sendDeleteMatchEvents";
import { sendPostMatchEvent } from "./sendPostMatchEvent";
import { DetailedEvent, MetricEvent } from "./utils/databaseTypes";
import { HttpRequestMethod, send } from "./utils/send";
import { EventFilters } from "../components/VideoReview/VideoReviewForm";
import { formatDateForRequest } from "../utils/formatDateForRequest";

export const getFilteredEventFilters = (
  filters: Partial<EventFilters>
): [string, any][] => {
  const { startDate, endDate, ...otherFilters } = filters;
  const transformedFilters = {
    ...otherFilters,
    startDate: formatDateForRequest(startDate ?? null),
    endDate: formatDateForRequest(endDate ?? null),
  };

  // Filter out empty string or empty list values.
  return Object.entries(transformedFilters).filter(
    ([_key, value]) => (value?.toString() ?? "") !== ""
  );
};

export const sendGetFilteredDetailedEvents = async (
  filters: Partial<EventFilters>
): Promise<DetailedEvent[]> => {
  const filteredEventFilters = getFilteredEventFilters(filters);
  const queryString = filteredEventFilters
    .map(([key, value]) => encodeURI(`${key}=${value}`))
    .join("&");

  return send<DetailedEvent[]>(
    `/detailed-events${queryString ? "?" : ""}${queryString}`,
    HttpRequestMethod.GET
  );
};

export const sendGetMatchDetailedEvents = async (
  matchId: number
): Promise<DetailedEvent[]> =>
  send<DetailedEvent[]>(
    `/matches/${matchId}/detailed-events`,
    HttpRequestMethod.GET
  );

export const sendGetMatchMetricEvents = async (
  matchId: number
): Promise<[MetricEvent]> =>
  send<[MetricEvent]>(
    `/matches/${matchId}/metric-events`,
    HttpRequestMethod.GET
  );

export const useQueryFilteredDetailedEvents = (
  filters: Partial<EventFilters> | undefined
): UseQueryResult<DetailedEvent[]> => {
  return useQuery(["detailedEvent", "filtered", filters], () =>
    filters ? sendGetFilteredDetailedEvents(filters) : null
  );
};

export const useQueryMatchDetailedEvents = (
  matchId: number
): UseQueryResult<DetailedEvent[]> =>
  useQuery(["detailedEvent", "match", matchId], () =>
    sendGetMatchDetailedEvents(matchId)
  );

export const useMatchMetricEvents = (
  matchId?: number
): MetricEvent[] | undefined =>
  useQuery(["metricEvent", "match", matchId], () =>
    matchId
      ? sendGetMatchMetricEvents(matchId)
      : Promise.resolve([] as MetricEvent[])
  ).data;

export const useUpdateDetailedEvent = <TData>(
  // Specifies the backend request to send to update the event.
  mutationFn: (variables: {
    detailedEvent: DetailedEvent;
    tag?: string;
  }) => Promise<TData>,
  onSuccess?: (data: TData) => void
) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: mutationFn,
    onMutate: async ({ detailedEvent }) => {
      queryClient.cancelQueries(["detailedEvent"]).then(() =>
        queryClient.setQueriesData(
          ["detailedEvent"],
          (prevDetailedEvents: DetailedEvent[] = []) => {
            // I'm not sure why, but if we don't use optional chaining, this can cause the following error:
            // TypeError: Cannot read properties of null (reading 'map')
            return prevDetailedEvents?.map((prevDetailedEvent) =>
              prevDetailedEvent.match_id === detailedEvent.match_id &&
              prevDetailedEvent.match_event_id === detailedEvent.match_event_id
                ? detailedEvent
                : prevDetailedEvent
            );
          }
        )
      );
    },
    onSuccess: onSuccess,
  });
};

export const useCreateDetailedEvent = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (detailedEvent: DetailedEvent) =>
      sendPostMatchEvent(detailedEvent),
    onMutate: async (detailedEvent) =>
      queryClient.cancelQueries(["detailedEvent"]).then(() =>
        queryClient.setQueryData(
          ["detailedEvent", "match", detailedEvent.match_id],
          (prevDetailedEvents: DetailedEvent[] = []) => {
            return [...prevDetailedEvents, detailedEvent];
          }
        )
      ),
    onSuccess: (postMatchEventResponse) => {
      if (!postMatchEventResponse) {
        alert("Error creating event. Please refresh and try again.");
        return;
      }
      // This updates the temporary match event id with the real one.
      queryClient.setQueryData(
        ["detailedEvent", "match", postMatchEventResponse.match_id],
        (prevDetailedEvents: DetailedEvent[] = []) =>
          prevDetailedEvents.map((prevDetailedEvent) =>
            prevDetailedEvent.match_event_id ===
            postMatchEventResponse.temp_match_event_id
              ? {
                  ...prevDetailedEvent,
                  match_event_id: postMatchEventResponse.match_event_id,
                }
              : prevDetailedEvent
          )
      );
    },
  });
};

export const useDeleteDetailedEvent = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({
      matchId,
      matchEventId,
    }: {
      matchId: number;
      matchEventId: number;
    }) => sendDeleteMatchEvents(matchId, [matchEventId]),
    onMutate: async ({ matchId, matchEventId }) =>
      queryClient
        .cancelQueries(["detailedEvent"])
        .then(() =>
          queryClient.setQueriesData(
            ["detailedEvent"],
            (prevDetailedEvents: DetailedEvent[] = []) =>
              prevDetailedEvents.filter(
                (prevDetailedEvent) =>
                  prevDetailedEvent.match_id !== matchId ||
                  prevDetailedEvent.match_event_id !== matchEventId
              )
          )
        ),
  });
};
