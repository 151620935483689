import { ActionConfig } from "./ActionConfig";
import { SingleActionConfig } from "./SingleActionConfig";
import { Action, DatabaseAction, ExpandedAction } from "../Action";
import {
  DetailedEvent,
  Match,
  Team,
} from "../../../requests/utils/databaseTypes";
import { Dispatch, SetStateAction } from "react";
import assert from "assert";
import { PostMatchEventResponse } from "../../../requests/sendPostMatchEvent";

export class MultiActionConfig extends ActionConfig {
  actionConfigs: SingleActionConfig[];
  firstAction: SingleActionConfig;

  constructor(action: Action, actionConfigs: SingleActionConfig[]) {
    assert(
      actionConfigs.every(
        (actionConfig) => actionConfig.action in DatabaseAction
      ),
      "All actionConfigs must be of type DatabaseAction."
    );

    const firstAction = actionConfigs[0];
    super(action, firstAction.nextActions, firstAction.getRequiresPitchClick);
    this.firstAction = firstAction;
    this.actionConfigs = actionConfigs;
  }

  getIsHomeTeamPossession = (
    previousEvents: DetailedEvent[],
    match: Match,
    possessionChangePseudoEvent: boolean
  ): boolean =>
    this.firstAction.getIsHomeTeamPossession(
      previousEvents,
      match,
      possessionChangePseudoEvent
    );

  isValid = (
    previousEvents: DetailedEvent[],
    pseudoEventActions: Set<ExpandedAction>,
    actionConfigs: Record<Action, ActionConfig>
  ): boolean =>
    this.firstAction.isValid(previousEvents, pseudoEventActions, actionConfigs);

  getClickPhrase = (actionTypes: Record<Action, string>) =>
    this.actionConfigs
      .map((actionConfig) => actionConfig.getClickPhrase(actionTypes))
      .join(" and ");

  getButtonPhrase = (
    actionTypes: Record<Action, string>,
    _homeTeam: Team,
    _awayTeam: Team
  ) =>
    this.actionConfigs
      .map((actionConfig) => actionConfig.getClickPhrase(actionTypes))
      .join(" + ");

  doAction = async (
    previousEvents: DetailedEvent[],
    createEvent: (
      action: DatabaseAction,
      possessionTeamId: number,
      locX: number,
      locY: number
    ) => Promise<PostMatchEventResponse>,
    pseudoEventActions: Set<ExpandedAction>,
    setPseudoEventActions: Dispatch<SetStateAction<Set<ExpandedAction>>>,
    match: Match,
    locX?: number,
    locY?: number
  ): Promise<void> => {
    for (const actionConfig of this.actionConfigs) {
      await actionConfig.doAction(
        previousEvents,
        createEvent,
        pseudoEventActions,
        setPseudoEventActions,
        match,
        locX,
        locY
      );
    }
  };
}
