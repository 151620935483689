import React from "react";
import { Button, ButtonProps, Tooltip } from "@mui/material";
import { usePermissionLockAndMessage } from "../hooks/usePermissionLockAndMessage";
import { UserPermission } from "../requests/utils/databaseTypes";

export type LockableButtonProps = Omit<ButtonProps, "disabled"> & {
  lockMessage?: string;
  isLocked?: boolean;
  minUserPermission?: UserPermission;
};

export const LockableButton = ({
  lockMessage = "",
  isLocked = false,
  minUserPermission,
  children,
  ...buttonProps
}: LockableButtonProps) => {
  const { permissionMessage, permissionLock } = usePermissionLockAndMessage(
    lockMessage,
    isLocked,
    minUserPermission
  );

  const button = (
    <Button {...buttonProps} disabled={isLocked || permissionLock}>
      {/*{permissionLock && <LockOutlinedIcon sx={{ ml: -1 }} />}*/}
      {children}
    </Button>
  );

  return !permissionLock ? (
    button
  ) : (
    <Tooltip title={permissionMessage} followCursor>
      <span>{button}</span>
    </Tooltip>
  );
};
