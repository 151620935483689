// TODO: continue to clean this file up
import * as d3 from "d3";
import React, { useState } from "react";
import { MaterialReactTable, type MRT_ColumnDef } from "material-react-table";
import { Box, Button, Grid, Stack } from "@mui/material";

import Plotly, { Data } from "plotly.js/lib/core";
import createPlotlyComponent from "react-plotly.js/factory";
import image from "../assets/rugbyPitch.jpg";
import {
  SimpleSelectOption,
  useActionOptions,
  useMatchOptions,
} from "../components/simple-select/simpleSelectOptions";
import { SingleSelect } from "../components/simple-select/SimpleSelect";
import { CSVLink } from "react-csv";
import { MetricEvent } from "../requests/utils/databaseTypes";
import { useMatchMetricEvents } from "../requests/sendGetMatchDetailedEvents";

// @ts-ignore
import Scatter from "plotly.js/lib/scatter";// Import the Scatter module
import { CustomSpinner } from "../components/CustomSpinner";
import { ChipSelect } from "../components/simple-select/ChipSelect"; 
Plotly.register([Scatter]); // Register the Scatter module with Plotly


const Plot = createPlotlyComponent(Plotly);

type Group = {
	group: string;
	MetersGained: number;
	TimeOfPossession: string; // Change the type to string
	territoryPct: string;
	tackles: number;
	penalties: number;
	points: number;
	home: string;
	away: string;
	color: string;
};

type GroupTranspose = {
	metric: string;
	Home: string;
	Away: string;
};

const formatTime = (seconds: number): string => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  return `${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
};

const transformEventsToStats = (
  filteredTableEvents: MetricEvent[]
): GroupTranspose[] => {
  const getAggregatedMetrics = (
    teamName: string,
    metricEvents: MetricEvent[]
  ): Group | undefined => {
    if (metricEvents.length === 0) {
      return undefined;
    }

    const territorySeconds = d3.sum(
      metricEvents,
      (d) => d.seconds_elapsed * d.territory
    );
    const totalSeconds = d3.sum(metricEvents, (d) => d.seconds_elapsed);
    return {
		group: teamName,
		MetersGained: d3.sum(metricEvents, (d) => d.meters_gained),
		TimeOfPossession: formatTime(
		d3.sum(metricEvents, (d) => d.seconds_elapsed * d.possession)
		),
		territoryPct: `${((100 * territorySeconds) / totalSeconds).toFixed(
		1
		)}%`,
		tackles: d3.sum(metricEvents, (d) => d.tackles),
		penalties: d3.sum(metricEvents, (d) => d.penalties),
		points: d3.sum(metricEvents, (d) => d.points),
		home: d3.max(metricEvents, (d) => d.home_team) ?? "Home Team",
		away: d3.max(metricEvents, (d) => d.away_team) ?? "Away Team",
		color: d3.max(metricEvents, (d) => d.color) ?? ""
	};
  };

  const teamMetricEvents = d3.group(filteredTableEvents, (d) => d.team);

  const homeTeamName = filteredTableEvents?.[0]?.home_team;
  const homeTeamAggregatedMetrics = getAggregatedMetrics(
    homeTeamName,
    teamMetricEvents.get(homeTeamName) || []
  );

  const awayTeamName = filteredTableEvents?.[0]?.away_team;
  const awayTeamAggregatedMetrics = getAggregatedMetrics(
    awayTeamName,
    teamMetricEvents.get(awayTeamName) || []
  );

  const fields: { key: keyof Group; headerName: string }[] = [
    { key: "points", headerName: "Points" },
    { key: "MetersGained", headerName: "Meters Gained" },
    { key: "TimeOfPossession", headerName: "Time Of Possession" },
    { key: "territoryPct", headerName: "Territory %" },
    { key: "tackles", headerName: "Tackles" },
	  { key: "penalties", headerName: "Penalties" },
	  { key: "color", headerName: "Color"},
    { key: "group", headerName: "TeamName" },
  ];

  return fields.map((field) => ({
    metric: field.headerName,
    Home: String(homeTeamAggregatedMetrics?.[field.key]) ?? "",
    Away: String(awayTeamAggregatedMetrics?.[field.key]) ?? "",
  }));
};
interface StatItem {
	metric: string;
	Home: string;
	Away: string;
}
function generateBarCharts(stats: StatItem[],
	teamNames: { Home: string, Away: string }, teamColors: { Home: string, Away: string }
) {
	const homeValues: number[] = [];
	const awayValues: number[] = [];
	const homeValuesNormalized: number[] = [];
	const awayValuesNormalized: number[] = [];
	const metricNames: string[] = [];

	// Iterate over each stat to collect all values and their corresponding metrics
	stats.forEach((stat: StatItem) => {
		let homeValue = parseFloat(stat.Home) || 0;
		let awayValue = parseFloat(stat.Away) || 0;
		let total = homeValue + awayValue;
		homeValues.push(homeValue);
		awayValues.push(awayValue);
		homeValuesNormalized.push((homeValue / total) * 100)
		awayValuesNormalized.push((awayValue / total) * 100)
		metricNames.push(stat.metric);
	});

	const homeBar: Data = {
		name: teamNames.Home,
		x: homeValuesNormalized,
		y: metricNames,
		text: homeValues.map(value => value.toString()),
		textposition: "inside",
		insidetextanchor: "start",
		type: 'bar',
		marker: {
			color: teamColors.Home, line: { color: "#000000", width: 2 }
		},
		orientation: 'h',
		width: .5,
	};

	const awayBar: Data = {
		name: teamNames.Away,
		x: awayValuesNormalized,
		y: metricNames,
		text: awayValues.map(value => value.toString()),
		type: 'bar',
		marker: {
			color: teamColors.Away, line: { color: "#000000", width: 2 }
		},
		orientation: 'h',
		width: .5,
	};

	type Annotation = {
		x: number,
		y: number,
		text: string,
		showarrow: boolean,
		font: {
			size: number,
			weight: string
		}
	};
	const annotations: Annotation[] = [];
	for (let i = 0; i < metricNames.length; i++) {
		annotations.push({
			x: 50,
			y: i + .45,
			text: metricNames[i],
			showarrow: false,
			font: {
				size: 14,
				weight: "bold"
			}
		})
	};

	const chartData = [homeBar, awayBar];

	return (

		<Plot
			data={chartData}
			layout={{
				barmode: 'relative',
				title: "none",
				xaxis: { showgrid: false, visible: false },
				yaxis: { showgrid: false, visible: false }, //position: .5, ticklabelposition: "outside right"},
				margin: { t: 0, b: 0, l: 5, r: 5 },
				legend: { orientation: "h", x: .5, y: 0, xanchor: "center" },
				autosize: true,
				width: 600,
				height: 400,
				paper_bgcolor: "#ffffff",
				plot_bgcolor: "#ffffff",
				hovermode: false,
				annotations: annotations,
			}}
		/>
	);
}

export const Analytics = () => {
	const [selectedFilterMatchId, setSelectedFilterMatchId] = useState("");
	const selectedFilterMatchIdInt = selectedFilterMatchId.length
	? parseInt(selectedFilterMatchId)
	: undefined;

	const metricEvents = useMatchMetricEvents(selectedFilterMatchIdInt);
	const matchOptions = useMatchOptions();
	const actionOptions = useActionOptions();
	const availableActionOptions =
	actionOptions?.filter((actionOption) =>
		metricEvents
		?.map((metricEvent) => metricEvent.action)
		.includes(actionOption.value)
	) ?? [];

	const [selectedActions, setSelectedActions] = useState<SimpleSelectOption[]>(
	[]
	);
	const [matchSelected, setMatchSelected] = useState(false);
	const [fileName, setFileName] = useState("");


  if (!matchOptions || !actionOptions) {
    return <CustomSpinner />;
  }

  const getFilteredTableEvents = (
    filterMatchId?: number,
    filterHalfNumbers?: number[]
  ): MetricEvent[] => {
    let filteredTableEvents = filterMatchId
      ? metricEvents?.filter((event) => event.match_id === filterMatchId)
      : metricEvents;
    if (filterHalfNumbers && filterHalfNumbers.length > 0) {
      filteredTableEvents = filteredTableEvents?.filter((event) =>
        filterHalfNumbers.includes(event.half_number)
      );
    }

    return filteredTableEvents ?? [];
  };

  const getFilteredPlotEvents = (
    filterMatchId?: number,
    filterActions?: SimpleSelectOption[]
  ): MetricEvent[] => {
    let filteredPlotEvents = filterMatchId
      ? metricEvents?.filter((event) => event.match_id === filterMatchId)
      : metricEvents;

    if (filterActions && filterActions.length > 0) {
      filteredPlotEvents = filteredPlotEvents?.filter((event) =>
        filterActions
          .map((filterAction) => filterAction.value)
          .includes(event.action)
      );
    }

    return filteredPlotEvents ?? [];
  };

  const filteredPlotEvents = getFilteredPlotEvents(
    selectedFilterMatchIdInt,
    selectedActions
  );

  const matchData = filteredPlotEvents?.filter(
    (event) => event.match_id === selectedFilterMatchIdInt
  );

  const scatterData: Data[] = [
    {
      x: matchData?.map((event) => event.x),
      y: matchData?.map((event) => event.y),
      mode: "markers",
      type: "scatter",
      marker: {
        size: matchData?.map((event) => event.meters_gained),
        color: matchData?.map((event) => event.color),
        opacity: 1.0,
      },
    },
  ];

  // Calculate totals with the selected filterMatchId and selectedHalfNumbers
	const filteredTableEvents = getFilteredTableEvents(selectedFilterMatchIdInt);
	const stats = transformEventsToStats(filteredTableEvents);
	const teamNames = {
		Home: stats.find((item) => item.metric === 'TeamName')?.Home || 'Home',
		Away: stats.find((item) => item.metric === 'TeamName')?.Away || 'Away',
	};
	const teamColors = {
		Home: stats.find((item) => item.metric === 'Color')?.Home || '#004aad',
		Away: stats.find((item) => item.metric === 'Color')?.Away || '#bb1555',
	}

  // Filter out the row with metric 'TeamName' (this code sucks -pjm)
	const filteredStats0 = stats.filter((row) => row.metric !== "TeamName");
	const filteredStats = filteredStats0.filter((row) => row.metric !== "Color");

  const columns: MRT_ColumnDef<GroupTranspose>[] = [
    { header: "", accessorKey: "metric", size: 100 },
    {
      header: stats.find((item) => item.metric === "TeamName")?.Home || "Home",
      accessorKey: "Home",
      size: 100,
    },
    {
      header: stats.find((item) => item.metric === "TeamName")?.Away || "Away",
      accessorKey: "Away",
      size: 100,
    },
  ];

  return (
    <>
      <Stack>
        <SingleSelect
          label="Select a Match to View Stats and Analytics"
          widthFactor={3}
          value={String(selectedFilterMatchId)}
          setValue={(value) => {
            const matchOption = matchOptions?.find(
              (matchOption) => matchOption.id === value
            );
            setSelectedFilterMatchId(value);
            setMatchSelected(value !== "");
            setFileName(matchOption?.value || "");
          }}
          options={matchOptions || []}
          none
        />
      </Stack>
      {metricEvents === undefined ? (
        <CustomSpinner />
      ) : (
        matchSelected && (
          <>
            <Box
              sx={{
                display: "flex",
                minHeight: "100px",
                justifyContent: "center",
                padding: "2em",
              }}
              >
              <Grid container spacing="1em">
                <Grid item xs={12} md={4}>
                  <h2 style={{ color: "#004aad" }}>Match Stats</h2>
                  <MaterialReactTable
                    enableTopToolbar={false}
                    enableBottomToolbar={false}
                    columns={columns}
                    data={filteredStats}
					/>
					<div style={{ width: '100%' }}>
						{generateBarCharts(filteredStats, teamNames, teamColors)}
					</div>
                    <CSVLink data={filteredTableEvents} filename={fileName}>
                      <Button variant="contained" sx={{ m: "2em" }}>
                        Download play-by-play data
                      </Button>
                    </CSVLink>

                </Grid>
                <Grid item xs={0} md={1}>
                </Grid>
                <Grid item xs={12} md={7}>
                    <h2 style={{ color: "#004aad" }}>Meters Gained Explorer</h2>
                    {scatterData && (
                      <Plot
                        data={scatterData}
                        layout={{
                          width: 765,
                          height: 511,
                          xaxis: {
                            range: [-14, 114], // Set the x-axis range
                            showgrid: false,
                            visible: false,
                          },
                          yaxis: {
                            range: [-7.5, 77.5],
                            showgrid: false,
                            visible: false,
                          },
                          margin: { t: 0, b: 0, l: 0, r: 0 },
                          images: [
                            {
                              source: image,
                              x: -14,
                              y: -7.5,
                              xref: "x",
                              yref: "y",
                              xanchor: "left",
                              yanchor: "bottom",
                              sizex: 128,
                              sizey: 85,
                              layer: "below",
                            },
                          ],
                        }}
                      />
                    )}
                    <ChipSelect
                      label="Select Action(s)"
                      widthFactor={3}
                      options={availableActionOptions}
                      values={selectedActions}
                      setValues={setSelectedActions}
                    />
               </Grid>
              </Grid>
            </Box>
          </>
        )
      )}
    </>
  );
};
