import * as React from "react";
import { useEffect, useState } from "react";
import { sendGetOrganizationClientSecret } from "../requests/sendGetOrganizationClientSecret";
import { CustomSpinner } from "../components/CustomSpinner";
import {
  STRIPE_PRICING_TABLE_KEY,
  STRIPE_PUBLIC_KEY,
} from "../utils/constants";

declare global {
  namespace JSX {
    interface IntrinsicElements {
      "stripe-pricing-table": React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLElement>,
        HTMLElement
      >;
    }
  }
}

export const Subscription = () => {
  const [clientSecret, setClientSecret] = useState<string>();
  useEffect(() => {
    sendGetOrganizationClientSecret().then((response) =>
      setClientSecret(response)
    );
  }, []);

  return clientSecret === undefined ? (
    <CustomSpinner />
  ) : (
    <stripe-pricing-table
      pricing-table-id={STRIPE_PRICING_TABLE_KEY}
      publishable-key={STRIPE_PUBLIC_KEY}
      customer-session-client-secret={clientSecret}
    ></stripe-pricing-table>
  );
};
