import { Match, PostTempIdResponse } from "./utils/databaseTypes";
import { HttpRequestMethod, send } from "./utils/send";
import { getUseCreate } from "./utils/useCrud/getUseCreate";
import { getUseUpdate } from "./utils/useCrud/getUseUpdate";

export type PostMatchRequest = {
  home_team_id: number;
  away_team_id: number;
  match_type_id: number;
  minutes_per_half: number;
  start_time: Date;
  location: string;
  video_url: string;
};

export type PostMatchResponse = {
  id: number;
};

const formatDate = (date: Date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");

  return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
};

export const sendPostMatch = (body: PostMatchRequest): Promise<Match> =>
  send<PostMatchResponse>("/matches", HttpRequestMethod.POST, body).then(
    (response) => ({ id: response.id, ...body, is_deleted: false } as Match)
  );

export const sendPostMatch2 = (match: Match): Promise<PostTempIdResponse> =>
  send<PostTempIdResponse>("/matches", HttpRequestMethod.POST, {
    ...match,
    start_time: formatDate(match.start_time),
  }).then((response) => ({
    ...response,
    temp_id: match.id,
  }));

const sendPutMatch = (match: Match): Promise<boolean> =>
  send<boolean>(`/matches/${match.id}`, HttpRequestMethod.PUT, {
    ...match,
    start_time: formatDate(match.start_time),
  });

export const useCreateMatch = getUseCreate(["matches"], sendPostMatch2);

export const useUpdateMatch = getUseUpdate(["matches"], sendPutMatch);
