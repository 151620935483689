import { MutableGridCol } from "./MutableGridCol";
import { MuiColorInput } from "mui-color-input";
import * as React from "react";
import { ReactNode } from "react";
import { GridCellParams, GridRenderCellParams } from "@mui/x-data-grid";
import { GridValidRowModel } from "@mui/x-data-grid/models/gridRows";
import assert from "assert";

export class NewColorPickerGridCol<
  Item extends GridValidRowModel
> extends MutableGridCol<Item> {
  renderCell: (params: GridRenderCellParams<Item>) => ReactNode;
  renderEditCell: (params: GridRenderCellParams<Item>) => ReactNode;

  constructor(
    field: Extract<keyof Item, string>,
    headerName: string,
    width: number
  ) {
    super(field, headerName, width);
    this.renderCell = (params: GridRenderCellParams<Item>) => (
      <MuiColorInput
        value={params.value}
        isAlphaHidden
        format="hex"
        size="small"
        disabled
      />
    );
    this.renderEditCell = (params) => (
      <MuiColorInput
        value={params.value}
        onChange={(color) =>
          params.api.setEditCellValue({ ...params, value: color })
        }
        fallbackValue={params.value}
        isAlphaHidden
        format="hex"
        size="small"
      />
    );
  }
}

export class ColorPickerGridCol<
  Item extends GridValidRowModel
> extends MutableGridCol<Item> {
  renderCell: (params: GridRenderCellParams<Item>) => ReactNode;
  renderEditCell: (params: GridRenderCellParams<Item>) => ReactNode;

  constructor(
    field: Extract<keyof Item, string>,
    headerName: string,
    width: number,
    rowEdits: { [p: string]: Item },
    setRowEdits: React.Dispatch<React.SetStateAction<{ [p: string]: Item }>>
  ) {
    super(field, headerName, width);

    this.renderCell = (params: GridRenderCellParams<Item>) => (
      <MuiColorInput
        value={params.value}
        isAlphaHidden
        format="hex"
        size="small"
        disabled
      />
    );

    const onColorInputChange = (params: GridCellParams, color: string) => {
      assert(params.id in rowEdits && params.id);
      setRowEdits((prevState) => {
        const newState = { ...prevState };
        // @ts-ignore TODO: GridRowId could be of type string and not number
        newState[params.id].color = color;
        return newState;
      });
    };

    this.renderEditCell = (params) => (
      <MuiColorInput
        value={rowEdits[params.id]?.color}
        onChange={(color) => onColorInputChange(params, color)}
        fallbackValue={params.value}
        isAlphaHidden
        format="hex"
        size="small"
      />
    );
  }
}
