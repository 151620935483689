import { createTheme, darken, lighten, ThemeProvider } from "@mui/material";
import { Hub } from "aws-amplify";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import React, { ReactElement, useEffect, useRef } from "react";
import { Navigate, Route, Routes } from "react-router";
import "./App.css";
import { Navigation } from "./Navigation";
import { Home } from "./pages/Home";
import { Tracker } from "./pages/Tracker";
import { VideoReview } from "./pages/VideoReview";
import { ColorPalette } from "./utils/constants";
import { getColorTokens } from "./utils/getColorTokens";
import {
  Authenticator,
  Theme as AmplifyTheme,
  ThemeProvider as AmplifyThemeProvider,
} from "@aws-amplify/ui-react";
import { useAuth } from "@aws-amplify/ui-react/internal";
import { Teams } from "./pages/Teams";
import { useNavigate } from "react-router-dom";
import { NewUser } from "./pages/NewUser";
import { useCreateExternalUser, useUser } from "./requests/sendGetUsers";
import { Subscription } from "./pages/Subscription";
import { CustomSpinner } from "./components/CustomSpinner";
import { Users } from "./pages/Users";
import { Matches } from "./pages/Matches";
import { UserAgreementSubmission } from "./pages/UserAgreementSubmission";
import { HandleCheckout } from "./pages/HandleCheckout";
import { Account } from "./pages/Account";
import { Analytics } from "./pages/Analytics";

const theme = createTheme({
  palette: {
    primary: getColorTokens(ColorPalette.blue),
    secondary: getColorTokens(ColorPalette.yellow),
  },
  typography: {
    fontFamily: ["Poppins", "sans-serif"].join(","),
  },
});

const getHueList = (colorHex: string) => ({
  10: { value: lighten(colorHex, 0.75) },
  20: { value: lighten(colorHex, 0.6) },
  40: { value: lighten(colorHex, 0.4) },
  60: { value: lighten(colorHex, 0.2) },
  80: { value: colorHex },
  90: { value: darken(colorHex, 0.25) },
  100: { value: darken(colorHex, 0.5) },
});

const getColorValueScale = (colorName: string) => ({
  10: { value: `{colors.${colorName}.10}` },
  20: { value: `{colors.${colorName}.20}` },
  40: { value: `{colors.${colorName}.40}` },
  60: { value: `{colors.${colorName}.60}` },
  80: { value: `{colors.${colorName}.80}` },
  90: { value: `{colors.${colorName}.90}` },
  100: { value: `{colors.${colorName}.100}` },
});

// Copied from here with some color changes: https://ui.docs.amplify.aws/
const amplifyTheme: AmplifyTheme = {
  name: "quicktap-theme",
  tokens: {
    colors: {
      blue: getHueList(ColorPalette.blue),
      maroon: getHueList(ColorPalette.maroon),
      neutral: {
        10: { value: "#e3dee3" },
        20: { value: "#bdb2bd" },
        40: { value: "#7b6a80" },
        60: { value: "#41354f" },
        80: { value: "#231934" },
        90: { value: "#180d2a" },
        100: { value: "#0c001f" },
      },
      brand: {
        primary: getColorValueScale("blue"),
        secondary: getColorValueScale("maroon"),
      },
      border: {
        primary: { value: "{colors.neutral.90}" },
        secondary: { value: "{colors.neutral.80}" },
        tertiary: { value: "{colors.neutral.60}" },
      },
    },
    borderWidths: {
      small: { value: "2px" },
      medium: { value: "4px" },
      large: { value: "8px" },
    },
    radii: {
      xs: { value: "1rem" },
      small: { value: "2rem" },
      medium: { value: "2rem" },
      large: { value: "2rem" },
      xl: { value: "3rem" },
    },
    space: {
      xs: { value: "0.75rem" },
      small: { value: "1rem" },
      medium: { value: "1.5rem" },
      large: { value: "2rem" },
      xl: { value: "1.5rem 3rem" },
    },
    components: {
      radio: {
        button: {
          padding: { value: "{borderWidths.small}" },
          borderWidth: { value: "{borderWidths.small}" },
        },
      },
    },
  },
};

const queryClient = new QueryClient();

const RequireAuth = ({ children }: { children: ReactElement }) => {
  const navigate = useNavigate();
  const createExternalUserQuery = useCreateExternalUser();
  let hasCreatedExternalUser = useRef(false);

  useEffect(() => {
    const authListener = (data: { payload: { event: any } }) => {
      switch (data?.payload?.event) {
        case "confirmSignUp":
          navigate("/new-user");
          break;
        case "autoSignIn":
          if (!hasCreatedExternalUser.current) {
            createExternalUserQuery.mutate();
          }
          hasCreatedExternalUser.current = true;
      }
    };
    Hub.listen("auth", authListener);
  }, [navigate, createExternalUserQuery]);

  if (createExternalUserQuery.isLoading) {
    return <CustomSpinner />;
  }

  return (
    <AmplifyThemeProvider theme={amplifyTheme}>
      <Authenticator
        loginMechanisms={["username", "email"]}
        initialState="signUp"
      >
        {children}
      </Authenticator>
    </AmplifyThemeProvider>
  );
};

const RequireSubscription = ({ children }: { children: ReactElement }) => {
  const user = useUser();
  const navigate = useNavigate();

  useEffect(() => {
    if (user?.organization_permissions === null) {
      navigate("/subscription");
    }
  }, [navigate, user]);

  if (user?.organization_permissions === undefined) {
    return <CustomSpinner />;
  }

  return children;
};

const RequireSignature = ({ children }: { children: ReactElement }) => {
  const user = useUser();
  const navigate = useNavigate();

  useEffect(() => {
    if (user?.user_agreement_signed === false) {
      navigate("/user-agreement-submission");
    }
  }, [navigate, user]);

  if (user?.user_agreement_signed === undefined) {
    return <CustomSpinner />;
  }

  return children;
};

const App = () => {
  const auth = useAuth();
  const cognitoUser = auth.user;

  return (
    <ThemeProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
        <Navigation />
        <Routes>
          <Route path="/">
            {/*<Route
              index
              element={
                auth.isLoading ? null : cognitoUser ? <Home /> : <Landing />
              }
            />*/}
            <Route
              index
              element={
                <RequireAuth>
                  <RequireSignature>
                    <RequireSubscription>
                      <Home />
                    </RequireSubscription>
                  </RequireSignature>
                </RequireAuth>
              }
            />
            <Route
              path="login"
              element={
                <RequireAuth>
                  {cognitoUser ? <Navigate to={"/"} /> : <div />}
                </RequireAuth>
              }
            />
            <Route
              path="new-user"
              element={
                <RequireAuth>
                  <NewUser />
                </RequireAuth>
              }
            />
            <Route
              path="account"
              element={
                <RequireAuth>
                  <Account />
                </RequireAuth>
              }
            />
            <Route
              path="subscription"
              element={
                <RequireAuth>
                  <Subscription />
                </RequireAuth>
              }
            />
            {/*This route is the redirect after a Stripe checkout.*/}
            <Route
              path="handle-checkout"
              element={
                <RequireAuth>
                  <HandleCheckout />
                </RequireAuth>
              }
            />
            <Route
              path="matches"
              element={
                <RequireAuth>
                  <RequireSignature>
                    <RequireSubscription>
                      <Matches />
                    </RequireSubscription>
                  </RequireSignature>
                </RequireAuth>
              }
            />
            <Route
              path="tracker/:matchId"
              element={
                <RequireAuth>
                  <RequireSignature>
                    <RequireSubscription>
                      <Tracker />
                    </RequireSubscription>
                  </RequireSignature>
                </RequireAuth>
              }
            />
            <Route
              path="video-review"
              element={
                <RequireAuth>
                  <RequireSignature>
                    <RequireSubscription>
                      <VideoReview />
                    </RequireSubscription>
                  </RequireSignature>
                </RequireAuth>
              }
            />
            <Route
              path="analytics"
              element={
                <RequireAuth>
                  <Analytics />
                </RequireAuth>
              }
            />
            <Route
              path="teams"
              element={
                <RequireAuth>
                  <RequireSignature>
                    <RequireSubscription>
                      <Teams />
                    </RequireSubscription>
                  </RequireSignature>
                </RequireAuth>
              }
            />
            <Route
              path="users"
              element={
                <RequireAuth>
                  <RequireSignature>
                    <RequireSubscription>
                      <Users />
                    </RequireSubscription>
                  </RequireSignature>
                </RequireAuth>
              }
            />
            <Route
              path="user-agreement-submission"
              element={<UserAgreementSubmission />}
            />
          </Route>
        </Routes>
      </QueryClientProvider>
    </ThemeProvider>
  );
};
export default App;
