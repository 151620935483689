import { HttpRequestMethod, send } from "./utils/send";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export type PostOrganizationRequest = {
  organization_name: string;
  user_name: string;
};

export const sendPostOrganization = (
  body: PostOrganizationRequest
): Promise<boolean> =>
  send<boolean>("/organizations", HttpRequestMethod.POST, body);

export const useCreateOrganization = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (body: PostOrganizationRequest) => sendPostOrganization(body),
    onSuccess: (response) => {
      if (!response) {
        alert("Error creating organization. Please refresh and try again.");
        return;
      }

      // We could mutate the user ourselves via onMutate, but it is simpler and reduces the opportunity for bugs to
      // invalidate and refetch the user.
      const invalidateUser = queryClient.invalidateQueries(["user"]);
      const invalidateOrganization = queryClient.invalidateQueries([
        "organizations",
      ]);
      return Promise.all([invalidateUser, invalidateOrganization]);
    },
  });
};
