import { Action, DatabaseAction, ExpandedAction } from "./Action";

// WARNING: YouTube shortcuts can not be implemented here, as it would cause shortcuts that trigger multiple events.
export const actionKeyboardShortcuts: Record<string, Action> = {
  t: DatabaseAction.tackled,
  r: DatabaseAction.ruck,
  f: ExpandedAction.tackledAndRuck,
  d: DatabaseAction.phase,
  e: DatabaseAction.offload,
  // d: DatabaseAction.pickAndGoPhase,
  // e: DatabaseAction.forwardsPhase,
  // s: DatabaseAction.backsPhase,
  // m: DatabaseAction.maulStart,

  a: DatabaseAction.openPlayKick,
  v: DatabaseAction.receiveKick,
  c: DatabaseAction.recoverKick,

  g: DatabaseAction.turnover,
  // g: DatabaseAction.breakdownTurnover,
  // c: DatabaseAction.contactTurnover,
  // v: DatabaseAction.handlingTurnover,

  // a: tag,
  q: ExpandedAction.homeTeamPenalty,
  w: ExpandedAction.awayTeamPenalty,
  z: ExpandedAction.homeTeamKnockOn,
  x: ExpandedAction.awayTeamKnockOn,
  s: DatabaseAction.forwardPass,
};

export const getActionKeyboardShortcut = (action: Action): string | undefined =>
  Object.entries(actionKeyboardShortcuts).find(
    ([, value]) => value === action
  )?.[0];
