import { useQuery } from "@tanstack/react-query";
import { MatchType } from "./utils/databaseTypes";
import { HttpRequestMethod, send } from "./utils/send";
import { useUser } from "./sendGetUsers";

const sendGetMatchTypes = async (): Promise<MatchType[]> =>
  send<MatchType[]>("/match-types", HttpRequestMethod.GET);

export const useMatchTypes = (): MatchType[] | undefined => {
  const user = useUser();
  return useQuery(["matchTypes"], sendGetMatchTypes, {
    enabled: user?.permission_level_id !== undefined,
  }).data;
};
