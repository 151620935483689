import { useAuth } from "@aws-amplify/ui-react/internal";
import { Button } from "@mui/material";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import quickTapLogo from "./assets/quickTapLogo.png";
import { useLocation } from "react-router";
import { useUser } from "./requests/sendGetUsers";
import { PersonOutline } from "@mui/icons-material";

export const Navigation = () => {
  const cognitoUser = useAuth().user;

  const quickTapUser = useUser();
  const navigate = useNavigate();
  const location = useLocation();

  if (
    quickTapUser &&
    quickTapUser?.organization_id == null &&
    location.pathname !== "/new-user"
  ) {
    navigate("/new-user");
  }

  /*const onSignIn = () => {
    navigate("/login");
  };*/

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Link to={"/"}>
        <img
          src={quickTapLogo}
          alt="QuickTap Rugby Analytics"
          height="50"
          style={{ margin: 16 }}
        />
      </Link>

      {cognitoUser !== undefined && (
        <div
          style={{
            padding: "1em 1em",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <>
            <Button
              onClick={() => navigate("/account")}
              startIcon={<PersonOutline />}
              variant="outlined"
            >
              {cognitoUser?.username ?? "Account"}
            </Button>
          </>
        </div>
      )}

      {/*
      {location.pathname !== "/login" && (
        <div
          style={{
            padding: "1em 1em",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {cognitoUser !== undefined ? (
            <>
              <div>{cognitoUser?.username}</div>
              <SignOutButton variant="outlined" size="small" />
            </>
          ) : (
            <div>
              <Button variant="outlined" size="small" onClick={onSignIn}>
                Sign In
              </Button>
            </div>
          )}
        </div>
      )}
        */}
    </div>
  );
};
