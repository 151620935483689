import { Box, Button, TextField } from "@mui/material";
import assert from "assert";
import React, { useEffect, useRef } from "react";
import { useUpdateDetailedEvent } from "../../requests/sendGetMatchDetailedEvents";
import { sendPutComment } from "../../requests/sendPutComment";
import {
  DetailedEvent,
  UserPermission,
} from "../../requests/utils/databaseTypes";
import { CustomModal } from "../CustomModal";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import { AlertDialog } from "../AlertDialog";
import { LockableButton } from "../LockableButton";
import { usePermissionLockAndMessage } from "../../hooks/usePermissionLockAndMessage";

export const CommentModal = ({
  event,
  setEventNull,
}: {
  event: DetailedEvent | null;
  setEventNull: () => void;
}) => {
  const ref = useRef<HTMLInputElement>(null);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = React.useState(false);
  const { permissionLock } = usePermissionLockAndMessage(
    "",
    false,
    UserPermission.write
  );

  useEffect(() => {
    if (!event) {
      return;
    }
    // setTimeout() is necessary to defer the operation until the stack is clear. https://stackoverflow.com/a/10576409
    setTimeout(() => {
      assert(ref.current);
      ref.current.focus();
      ref.current.selectionStart = ref.current.selectionEnd =
        event?.comment?.length ?? 0;
    }, 0);
  }, [event]);

  const updateComment = useUpdateDetailedEvent(async ({ detailedEvent }) => {
    await sendPutComment(
      detailedEvent.match_id,
      detailedEvent.match_event_id,
      detailedEvent.comment ?? ""
    );
  }).mutate;

  const onClose = () => {
    assert(event);
    if (event.comment ?? "" === ref.current?.value ?? "") {
      setEventNull();
    } else {
      setIsDeleteDialogOpen(true);
    }
  };

  const onSave = () => {
    assert(event);
    if (event.comment !== ref.current?.value) {
      const updatedEvent = { ...event, comment: ref.current?.value };
      updateComment({ detailedEvent: updatedEvent });
    }

    setEventNull();
  };

  return (
    <CustomModal open={!!event} onClose={onClose}>
      <>
        <AlertDialog
          message="Discard comment changes?"
          isOpen={isDeleteDialogOpen}
          onCloseDialog={() => setIsDeleteDialogOpen(false)}
          onConfirmDialog={setEventNull}
          closeText="Go Back"
          confirmText="Discard"
        />
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          marginX={1}
        >
          <TextField
            id={`${event?.match_id},${event?.match_event_id}`}
            inputRef={ref}
            label="Comment"
            multiline
            rows={4}
            fullWidth
            defaultValue={event?.comment}
            sx={{ m: 1 }}
            disabled={permissionLock}
          />
          <Box>
            <Button
              onClick={setEventNull}
              startIcon={<CancelIcon />}
              variant="outlined"
              color="error"
              sx={{ m: 1 }}
            >
              Cancel
            </Button>
            <LockableButton
              onClick={onSave}
              startIcon={<SaveIcon />}
              variant="outlined"
              sx={{ m: 1 }}
              minUserPermission={UserPermission.write}
            >
              Save
            </LockableButton>
          </Box>
        </Box>
      </>
    </CustomModal>
  );
};
