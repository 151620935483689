import { useQuery, useQueryClient } from "@tanstack/react-query";
import { Tag } from "./utils/databaseTypes";
import { HttpRequestMethod, send } from "./utils/send";
import { useUser } from "./sendGetUsers";

export const sendGetTags = (): Promise<Tag[]> =>
  send<Tag[]>("/tags", HttpRequestMethod.GET);

export const useTagsQuery = () => {
  const user = useUser();
  return useQuery(["tags"], sendGetTags, {
    enabled: user?.permission_level_id !== undefined,
  });
};

export const useTags = (): Tag[] => useTagsQuery()?.data ?? [];

export const useAddTagToState = (): ((tag: string) => void) => {
  const queryClient = useQueryClient();
  return (newTagName: string) =>
    queryClient.setQueryData(["tags"], (prevTags: Tag[] = []) => {
      queryClient.cancelQueries(["tags"]).then(() => {});
      const newTag: Tag = {
        id: Math.max(...prevTags.map((tag) => tag.id)) + 1,
        name: newTagName,
      };
      return prevTags.map((tag) => tag.name).includes(newTag.name)
        ? prevTags
        : [...prevTags, newTag];
    });
};
