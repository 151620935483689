import { renderTime } from "../utils/time";
import { getDisplayDate } from "../utils/getDisplayDate";
import {
  EventGridRowModel,
  getEventGridRowId,
} from "../components/event-grid/EventGrid";
import { DetailedEvent } from "../requests/utils/databaseTypes";
import { useActionTypes } from "../requests/sendGetActionTypes";
import { useGetTeam } from "../requests/sendGetTeams";
import { useMatches } from "../requests/sendGetMatches";

export const useEventsToEventGridRowModels = (): ((
  events: DetailedEvent[]
) => EventGridRowModel[]) => {
  const actionTypes = useActionTypes();
  const getTeam = useGetTeam();
  const matches = useMatches();

  return (events: DetailedEvent[]) =>
    events
      .filter((event) => !event.is_deleted)
      .map((event) => {
        const match = matches?.find((_match) => _match.id === event.match_id);
        return {
          ...event,
          // id is used as the key for each row
          // getGridRowElement() assumes that this will always be how `id` is calculated
          id: getEventGridRowId(event.match_id, event.match_event_id),
          time: renderTime(event.event_time_in_seconds),
          action: actionTypes[event.action_type_id],
          possession_team: getTeam(event.begin_team_id),
          opposing_team: getTeam(
            event.begin_team_id === match!.away_team_id
              ? match!.home_team_id
              : match!.away_team_id
          ),
          match_date: getDisplayDate(match!.start_time),
          tags: event.tags,
        } as EventGridRowModel;
      });
};
