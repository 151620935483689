import { useQuery } from "@tanstack/react-query";
import { Match } from "./utils/databaseTypes";
import { HttpRequestMethod, send } from "./utils/send";
import { useUser } from "./sendGetUsers";
import assert from "assert";

export const sendGetMatches = (): Promise<Match[]> =>
  send<Match[]>("/matches", HttpRequestMethod.GET).then((matches) =>
    matches.map((match) => {
      assert(match.start_time);
      return {
        ...match,
        start_time: new Date(match.start_time),
      };
    })
  );

export const useMatches = (): Match[] | undefined => {
  const user = useUser();
  return useQuery(["matches"], sendGetMatches, {
    enabled: user?.permission_level_id !== undefined,
  }).data;
};

export const useMatch = (id?: number): Match | undefined =>
  useMatches()?.find((match) => match.id === id);

export const useGetMatch = (): ((id: number | null) => Match | undefined) => {
  const matches = useMatches();
  return (id: number | null) => matches?.find((match) => match.id === id);
};
