import { HttpRequestMethod, send } from "./utils/send";

export const sendPutEventTag = (
  matchId: number,
  matchEventId: number,
  tag: string
): Promise<boolean> =>
  send<boolean>(
    `/matches/${matchId}/events/${matchEventId}/tags/${tag}`,
    HttpRequestMethod.PUT
  );
