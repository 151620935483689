import { Button, ButtonGroup, Link, Stack, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { SignOutButton } from "../components/SignOutButton";
import { Users } from "./Users";
import Box from "@mui/material/Box";

export const Account = () => {
  const navigate = useNavigate();

  return (
    <div style={{ margin: "2em" }}>
      <h2>Account Settings</h2>
      <ButtonGroup orientation="vertical" sx={{ width: "fit-content" }}>
        <Stack spacing={1}>
          <Button
            onClick={() => navigate("/subscription")}
            variant="outlined"
            sx={{ width: "auto" }}
          >
            Change Subscription
          </Button>
          <SignOutButton variant="outlined" sx={{ width: "auto" }} />
        </Stack>
      </ButtonGroup>

      <h2>Organization Users</h2>
      <Box
        sx={{
          height: "40vh",
        }}
      >
        <Users />
      </Box>
      <h2>Contact QuickTap</h2>
      <Typography>
        Feedback, questions, or bug reports? Email us at{" "}
        <Link href="mailto:support@getquicktap.com">
          support@getquicktap.com
        </Link>
      </Typography>
    </div>
  );
};
