import { HttpRequestMethod, send } from "./utils/send";

export const sendPutHandleCheckout = (
  checkoutSessionId: string
): Promise<boolean> => {
  return send<boolean>(
    `/handle-checkout/${checkoutSessionId}`,
    HttpRequestMethod.PUT
  );
};
