import { EventGridRowModel } from "../event-grid/EventGrid";
import { useGetMatch } from "../../requests/sendGetMatches";
import { useGetTeam } from "../../requests/sendGetTeams";
import { useActionTypes } from "../../requests/sendGetActionTypes";
import Card from "@mui/material/Card";
import { Avatar, CardActionArea, Chip, lighten } from "@mui/material";
import { ColorPalette } from "../../utils/constants";
import CardHeader from "@mui/material/CardHeader";
import { pickTextColor } from "../../utils/pickTextColor";
import { CommentInput } from "../CommentInput";
import { renderTime } from "../../utils/time";
import { getMatchName } from "../../utils/getMatchName";
import { getDisplayDate } from "../../utils/getDisplayDate";
import CardContent from "@mui/material/CardContent";
import { TagsInput } from "../TagsInput";
import React from "react";

type Props = {
  event: EventGridRowModel;
  eventIndex: number;
  isActiveEvent: boolean;
  setCurrentEventIndex: (eventIndex: number) => void;
  pauseVideo: () => void;
};

export const EventCard = ({
  event,
  eventIndex,
  isActiveEvent,
  setCurrentEventIndex,
  pauseVideo,
}: Props) => {
  const getMatch = useGetMatch();
  const getTeam = useGetTeam();
  const actionTypes = useActionTypes();

  const match = getMatch(event.match_id);

  return (
    <Card
      id={`event-card-${event.match_id}-${event.match_event_id}`}
      sx={{
        mb: 0.75,
        mr: 1,
        backgroundColor: isActiveEvent
          ? lighten(ColorPalette.blue, 0.8)
          : lighten(ColorPalette.gray, 0.95),
      }}
    >
      <CardActionArea
        component="span"
        onClick={(e) => {
          setCurrentEventIndex(eventIndex);
          // The video will be played if the event index is changed.
          // playVideo();
          e.stopPropagation();
        }}
      >
        <CardHeader
          sx={{
            paddingBottom: 0,
            display: "flex",
            alignItems: "center",
            ".MuiCardHeader-action": {
              alignSelf: "center",
            },
          }}
          avatar={
            <Avatar
              sx={{
                bgcolor: event.possession_team?.color,
                color: pickTextColor(event.possession_team?.color ?? "#FFFFFF"),
                border: 1,
                fontFamily: "serif",
              }}
            >
              {event.possession_team?.name?.[0]}
            </Avatar>
          }
          action={
            <>
              <CommentInput
                event={event}
                isActiveEvent={isActiveEvent}
                pauseVideo={pauseVideo}
              />
              {/*<IconButton component="span">*/}
              {/*  <MoreVertIcon />*/}
              {/*</IconButton>*/}
            </>
          }
          title={
            <span
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <span style={{ marginRight: "0.5em" }}>
                {actionTypes[event.action_type_id]}
              </span>
              <Chip
                label={renderTime(event.event_time_in_seconds)}
                variant="filled"
                size="small"
              />
            </span>
          }
          subheader={getMatchName(
            getTeam(match!.home_team_id)?.name,
            getTeam(match!.away_team_id)?.name,
            getDisplayDate(match!.start_time)
          )}
        />
        <CardContent
          sx={{
            py: 0,
            "&:last-child": {
              paddingBottom: 0,
            },
          }}
        >
          <TagsInput
            event={event}
            isActiveEvent={isActiveEvent}
            pauseVideo={pauseVideo}
          />
        </CardContent>
      </CardActionArea>
    </Card>
  );
};
