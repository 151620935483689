import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import { Grid, IconButton } from "@mui/material";
import { useUpdateDetailedEvent } from "../../requests/sendGetMatchDetailedEvents";
import { sendDeleteMatchEvents } from "../../requests/sendDeleteMatchEvents";
import {
  PostMatchEventResponse,
  sendPostMatchEvent,
} from "../../requests/sendPostMatchEvent";
import { useGetMatch } from "../../requests/sendGetMatches";
import { DetailedEvent } from "../../requests/utils/databaseTypes";
import { useQueryClient } from "@tanstack/react-query";
import { ReactElement, useState } from "react";
import {
  EventGridRowModel,
  getEventGridRowId,
} from "../../components/event-grid/EventGrid";

export const usePossessionTeamColumn = (): ((
  row: EventGridRowModel
) => ReactElement) => {
  const [disabledClickSwapIds, setDisabledClickSwapIds] = useState<string[]>(
    []
  );

  const getMatch = useGetMatch();
  const queryClient = useQueryClient();

  const updatePossession = useUpdateDetailedEvent(
    async ({ detailedEvent }): Promise<PostMatchEventResponse> => {
      const postMatchEventResponse = sendPostMatchEvent(detailedEvent);
      const deleteMatchEventsResponse = sendDeleteMatchEvents(
        detailedEvent.match_id,
        [detailedEvent.match_event_id]
      );
      await deleteMatchEventsResponse;
      return await postMatchEventResponse;
    },
    (postMatchEventResponse) =>
      queryClient.setQueriesData(
        ["detailedEvent"],
        (prevDetailedEvents: DetailedEvent[] = []) =>
          prevDetailedEvents.map((prevDetailedEvent) =>
            prevDetailedEvent.match_id === postMatchEventResponse.match_id &&
            prevDetailedEvent.match_event_id ===
              postMatchEventResponse.temp_match_event_id
              ? {
                  ...prevDetailedEvent,
                  ...postMatchEventResponse,
                  // Changing the `id` will refresh the row in the list, so we won't need to remove the old one.
                  id: getEventGridRowId(
                    postMatchEventResponse.match_id,
                    postMatchEventResponse.temp_match_event_id
                  ),
                }
              : prevDetailedEvent
          )
      )
  ).mutate;

  return (row) => {
    const match = getMatch(row.match_id);
    const getOtherTeam = (teamId: number) =>
      Number(
        teamId === match!.home_team_id
          ? match!.away_team_id
          : match!.home_team_id
      );

    const onClickSwap = () => {
      // removed in the OnSuccess event
      setDisabledClickSwapIds((prevState) => [...prevState, row.id]);
      const newEvent: DetailedEvent = {
        ...row,
        begin_team_id: getOtherTeam(row.begin_team_id),
        end_team_id: getOtherTeam(row.end_team_id),
      };
      updatePossession({ detailedEvent: newEvent });
    };

    return (
      <Grid container display="flex" direction="column">
        <div
          style={{
            textAlign: "center",
            marginTop: "0.2em",
            marginBottom: "-0.3em",
          }}
        >
          {row.possession_team.name}
        </div>
        <IconButton
          sx={{ mt: "-0.3em", mb: "-0.2em" }}
          onClick={onClickSwap}
          disabled={disabledClickSwapIds.includes(row.id)}
        >
          <SwapHorizIcon fontSize="small" />
        </IconButton>
      </Grid>
    );
  };
};
