import * as React from "react";
import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import {
  DataGrid,
  GridColDef,
  GridEventListener,
  GridRowEditStopReasons,
  GridRowId,
  GridRowModel,
  GridRowModes,
  GridRowModesModel,
  GridRowsProp,
  GridSortDirection,
  GridToolbarContainer,
  useGridApiContext,
} from "@mui/x-data-grid";
import {
  getLargeRandomInt,
  isLargeRandomInt,
} from "../../utils/getLargeRandomInt";
import { GridValidRowModel } from "@mui/x-data-grid/models/gridRows";
import { MEDIUM_COLUMN, SMALL_COLUMN } from "../../utils/columnSizes";
import { IconButton } from "@mui/material";
import { CustomSpinner } from "../CustomSpinner";
import { GridRowParams } from "@mui/x-data-grid/models/params/gridRowParams";
import { useInterval } from "usehooks-ts";
import { REFRESH_INTERVAL_MILLISECONDS } from "../../utils/constants";
import {
  OrganizationPermissions,
  PostTempIdResponse,
  UserPermission,
} from "../../requests/utils/databaseTypes";
import { AlertDialog } from "../AlertDialog";
import { IS_LOCAL } from "../../requests/utils/constants";
import { ImmutableGridCol } from "./columns/ImmutableGridCol";
import assert from "assert";
import { LockableButton } from "../LockableButton";
import { ConstGridCol } from "./columns/ConstGridCol";
import { useUser } from "../../requests/sendGetUsers";
import { LockableIconButton } from "../LockableIconButton";

export type CrudRequests<T> = {
  useCollection: () => T[] | undefined;
  postSingleton: (body: T) => Promise<PostTempIdResponse>;
  putSingleton: (body: T) => void;
  deleteSingleton: (id: number) => void;
};

type Props<T> = {
  crudRequests: CrudRequests<T>;
  validators: {
    isValidCreate: (item?: T) => boolean | undefined;
    isValidDelete?: (item: T) => boolean;
  };
  columns: GridColDef[];
  getNewRow: (id: number) => T;
  getDeleteDialog: (id: number | null) => string;
  unableToDeleteTooltip: string;
  itemName: string;
  lockKey?: keyof OrganizationPermissions;
  initialSortModel: { field: keyof T & string; sort: GridSortDirection }[];
  fieldToFocus: keyof T & string;
};

interface EditToolbarProps {
  setRows: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void;
  setRowModesModel: (
    newModel: (oldModel: GridRowModesModel) => GridRowModesModel
  ) => void;
}

export const FullFeaturedCrudGrid = <
  T extends GridValidRowModel & { id: number; name?: string }
>({
  crudRequests: { useCollection, postSingleton, putSingleton, deleteSingleton },
  validators: { isValidCreate, isValidDelete = () => true },
  columns,
  getNewRow,
  getDeleteDialog,
  unableToDeleteTooltip,
  itemName,
  lockKey,
  initialSortModel,
  fieldToFocus,
}: Props<T>) => {
  // We need to wait for the dialog to close before setting the alert dialog item to null, otherwise the dialog
  // will momentarily show `undefined`.
  const [isAlertDialogOpen, setIsAlertDialogOpen] = useState(false);
  const [alertDialogDeleteId, setAlertDialogDeleteId] = useState<number | null>(
    null
  );

  const items = useCollection();
  const [rows, setRows] = useState<(T & { isNew?: boolean })[]>();
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});

  useEffect(() => {
    if (!rows && !!items) {
      setRows(items);
    }
  }, [rows, items]);

  if (!rows) {
    return <CustomSpinner />;
  }

  const AddToolbar = ({ setRows, setRowModesModel }: EditToolbarProps) => {
    const user = useUser();

    if (!user) {
      return <CustomSpinner />;
    }

    const handleClick = () => {
      const id = getLargeRandomInt();
      setRows((oldRows) => [...oldRows, { ...getNewRow(id), isNew: true }]);
      setRowModesModel((oldModel) => ({
        ...oldModel,
        [id]: { mode: GridRowModes.Edit, fieldToFocus: fieldToFocus },
      }));
    };

    // const isLocked = !locks
    //   ?.filter((lock) => lock.maxItemsCount > (items?.length ?? 0))
    //   .some((lock) =>
    //     user.organization_permissions?.includes(lock.maxItemsPermission)
    //   );

    let isLocked: boolean;
    if (user.organization_permissions === null) {
      isLocked = true;
    } else {
      isLocked =
        lockKey !== undefined &&
        (user.organization_permissions?.[lockKey] ?? Infinity) <=
          (items?.length ?? 0);
    }
    return (
      <GridToolbarContainer>
        <LockableButton
          color="primary"
          startIcon={<AddIcon />}
          onClick={handleClick}
          isLocked={isLocked}
          lockMessage={`Adding another ${itemName} requires a QuickTap Pro account.`}
          minUserPermission={UserPermission.write}
        >
          Add {itemName}
        </LockableButton>
      </GridToolbarContainer>
    );
  };

  const handleRowEditStop: GridEventListener<"rowEditStop"> = (
    params,
    event
  ) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const onEditClick = (id: GridRowId) => {
    setRowModesModel((prevState) => ({
      ...prevState,
      [id]: { mode: GridRowModes.Edit },
    }));
  };

  const onSaveClick = (id: GridRowId) => {
    setRowModesModel((prevState) => ({
      ...prevState,
      [id]: { mode: GridRowModes.View },
    }));
  };

  const onDeleteClick = (id: GridRowId) => {
    setAlertDialogDeleteId(Number(id));
    setIsAlertDialogOpen(true);
  };

  const onCancelClick = (id: GridRowId) => {
    setRowModesModel((prevState) => ({
      ...prevState,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    }));

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow!.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const processRowUpdate = (newRow: GridRowModel<T>) => {
    // There are some values that aren't in the row because they aren't editable, but we need to post them.
    const rowWithAllValues = {
      ...items?.find((row) => row.id === newRow.id),
      ...newRow,
    };
    if (isLargeRandomInt(newRow.id)) {
      postSingleton(rowWithAllValues as T).then((response) => {
        setRows((prevState) =>
          prevState!.map((row) =>
            row.id === response.temp_id ? { ...row, id: response.id } : row
          )
        );
      });
    } else {
      putSingleton(rowWithAllValues as T);
    }
    const updatedRow = { ...newRow, isNew: false };
    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const SaveButton = ({ id, row }: GridRowParams) => {
    const gridApiRef = useGridApiContext();
    const [updatedRow, setUpdatedRow] = useState<T>(row as T);

    // If we don't manually refresh the state, the save button will not know when to enable/disable.
    useInterval(
      () =>
        setUpdatedRow(gridApiRef.current.getRowWithUpdatedValues(id, "") as T),
      REFRESH_INTERVAL_MILLISECONDS
    );

    return (
      <IconButton
        onClick={() => onSaveClick(id)}
        color="primary"
        disabled={!isValidCreate(updatedRow)}
      >
        <SaveIcon />
      </IconButton>
    );
  };

  const idColumn: GridColDef[] = [
    // If ever displaying team ID to real users, this must be modified, as we initially create a random ID.
    ...(IS_LOCAL ? [new ImmutableGridCol("id", "ID", SMALL_COLUMN)] : []),
  ];
  columns = idColumn.concat(columns).concat([
    {
      field: "actions",
      type: "actions",
      headerName: "",
      width: MEDIUM_COLUMN,
      getActions: (props) => {
        const { id, row } = props;
        return rowModesModel[id]?.mode === GridRowModes.Edit
          ? [
              <SaveButton {...props} />,
              <IconButton onClick={() => onCancelClick(id)} color="primary">
                <CancelIcon />
              </IconButton>,
            ]
          : [
              <LockableIconButton
                lockMessage=""
                isLocked={isLargeRandomInt(id)}
                onClick={() => onEditClick(id)}
                color="primary"
                minUserPermission={UserPermission.write}
              >
                <EditIcon />
              </LockableIconButton>,

              <LockableIconButton
                lockMessage={unableToDeleteTooltip}
                isLocked={isLargeRandomInt(id) || !isValidDelete(row)}
                tooltipProps={{
                  enterDelay: 500,
                  followCursor: true,
                  disableHoverListener: isValidDelete(row),
                }}
                onClick={() => onDeleteClick(row.id)}
                color="error"
                minUserPermission={UserPermission.write}
              >
                <DeleteIcon />
              </LockableIconButton>,
            ];
      },
    },
  ]);

  return (
    <Box
      sx={{
        width: "100%",
        "& .actions": {
          color: "text.secondary",
        },
        "& .textPrimary": {
          color: "text.primary",
        },
      }}
    >
      <AlertDialog
        message={getDeleteDialog(alertDialogDeleteId)}
        isOpen={isAlertDialogOpen}
        onCloseDialog={() => {
          setIsAlertDialogOpen(false);
          // We need to wait for the dialog to close before setting the alert dialog item to null, otherwise the dialog
          // will momentarily show `undefined`.
          setTimeout(() => setAlertDialogDeleteId(null), 100);
        }}
        onConfirmDialog={() => {
          assert(alertDialogDeleteId !== null);
          deleteSingleton(alertDialogDeleteId);
          setRows((prevState) =>
            prevState?.filter((row) => row.id !== alertDialogDeleteId)
          );
        }}
      />
      <DataGrid
        rows={rows}
        columns={columns}
        editMode="row"
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        slots={{
          toolbar: AddToolbar,
          // noRowsOverlay: () => <></>,
        }}
        slotProps={{
          toolbar: { setRows, setRowModesModel },
        }}
        hideFooter={true}
        // Prevent editing of fields that should only be set on row creation.
        isCellEditable={(params) => {
          const constGridCols = columns
            .filter((column) => column instanceof ConstGridCol)
            .map((column) => column.field);
          return (
            isLargeRandomInt(params.id ?? 0) ||
            !constGridCols.includes(params.field)
          );
        }}
        initialState={{
          sorting: {
            sortModel: initialSortModel,
          },
        }}
        sx={{
          m: 1,
          [`& .MuiDataGrid-cell:focus, & .MuiDataGrid-cell:focus-within`]: {
            outline: "none",
          },
          [`& .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-columnHeader:focus-within`]:
            {
              outline: "none",
            },
        }}
      />
    </Box>
  );
};
