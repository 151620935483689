import { SingleActionConfig } from "./SingleActionConfig";
import { DatabaseAction } from "../Action";
import { DetailedEvent } from "../../../requests/utils/databaseTypes";

export class LineoutActionConfig extends SingleActionConfig {
  protected getIsPossessionSwitch = (
    previousEvents: DetailedEvent[]
  ): boolean => {
    const previousNonFlagEvents = this.getNonFlagEvents(previousEvents);

    // Possession does not change for the lineout if it is a result of a penalty or free kick restart.
    return !(
      previousNonFlagEvents[2] !== undefined &&
      previousNonFlagEvents[1] !== undefined &&
      previousNonFlagEvents[0] !== undefined &&
      [DatabaseAction.penaltyWon, DatabaseAction.freeKickWon].includes(
        previousNonFlagEvents[2].action_type_id
      ) &&
      previousNonFlagEvents[1].action_type_id === DatabaseAction.kickRestart &&
      previousNonFlagEvents[0].action_type_id === DatabaseAction.intoTouch
    );
  };
}
