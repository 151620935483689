import * as React from "react";
import { GridColDef } from "@mui/x-data-grid";
import { LARGE_COLUMN, XL_COLUMN, XXL_COLUMN } from "../utils/columnSizes";
import { CustomSpinner } from "../components/CustomSpinner";
import { useGetMatch, useMatches } from "../requests/sendGetMatches";
import { MutableGridCol } from "../components/CrudTable/columns/MutableGridCol";
import { Match } from "../requests/utils/databaseTypes";
import { useCreateMatch, useUpdateMatch } from "../requests/sendPostMatch";
import { useDeleteMatch } from "../requests/sendDeleteMatch";
import { isValidVideoUrl } from "../utils/isValidVideoUrl";
import { NewSelectorGridCol } from "../components/CrudTable/columns/SelectorGridCol";
import { useGetTeam, useTeams } from "../requests/sendGetTeams";
import { lighten } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ColorPalette } from "../utils/constants";
import { DateGridCol } from "../components/CrudTable/columns/DateTimeGridCol";
import { ButtonGridCol } from "../components/CrudTable/columns/ButtonGridCol";
import { isLargeRandomInt } from "../utils/getLargeRandomInt";
import { FullFeaturedCrudGrid } from "../components/CrudTable/FullFeaturedCrudGrid";
import { useMatchTypes } from "../requests/sendGetMatchTypes";
import { getMatchName } from "../utils/getMatchName";
import { getDisplayDate } from "../utils/getDisplayDate";

const isValidMatch = (match?: Match): boolean =>
  !!(
    match &&
    match.home_team_id &&
    match.away_team_id &&
    match.start_time &&
    isValidVideoUrl(match.video_url)
  );

export const Matches = () => {
  const teams = useTeams();
  const getTeam = useGetTeam();
  const matches = useMatches();
  const matchTypes = useMatchTypes();
  const getMatch = useGetMatch();
  const createMatch = useCreateMatch().mutateAsync;
  const updateMatch = useUpdateMatch().mutate;
  const deleteMatch = useDeleteMatch().mutate;

  const navigate = useNavigate();

  if (!matches || !matchTypes || !teams) {
    return <CustomSpinner />;
  }

  const columns: GridColDef[] = [
    new ButtonGridCol(
      "load",
      "Load",
      LARGE_COLUMN,
      (params) => navigate(`/tracker/${params.id}`),
      lighten(ColorPalette.blue, 0.25),
      (params) => isLargeRandomInt(params.row.id)
    ),
    new NewSelectorGridCol<Match>(
      "home_team_id",
      "Home Team",
      XL_COLUMN,
      teams.map((team) => ({ value: team.id, label: team.name })),
      (params) => getTeam(params.value)?.name ?? ""
    ),
    new NewSelectorGridCol<Match>(
      "away_team_id",
      "Away Team",
      XL_COLUMN,
      teams.map((team) => ({ value: team.id, label: team.name })),
      (params) => getTeam(params.value)?.name ?? ""
    ),
    new NewSelectorGridCol<Match>(
      "match_type_id",
      "Match Type",
      LARGE_COLUMN,
      matchTypes.map((matchType) => ({
        value: matchType.id, // numeric representation
        label: matchType.name, // string representation
      })),
      (params) =>
        matchTypes.find((matchType) => matchType.id === params.value)!.name
    ),
    new DateGridCol("start_time", "Date", XL_COLUMN),
    new MutableGridCol("location", "Location", XXL_COLUMN),
    new MutableGridCol("video_url", "YouTube URL", XXL_COLUMN),
  ];

  const getNewRow = (id: number): Match => ({
    id,
    home_team_id: "",
    away_team_id: "",
    start_time: new Date(),
    video_url: "",
    location: "",
    match_type_id: 1,
  });

  const getDeleteDialog = (id: number | null) => {
    const match = getMatch(id);
    return `Delete ${getMatchName(
      getTeam(match?.home_team_id)?.name,
      getTeam(match?.away_team_id)?.name,
      getDisplayDate(match?.start_time)
    )}?`;
  };
  return (
    <FullFeaturedCrudGrid
      crudRequests={{
        useCollection: useMatches,
        postSingleton: (match: Match) => createMatch(match),
        putSingleton: (match: Match) => updateMatch(match),
        deleteSingleton: (id: number) => deleteMatch(id),
      }}
      validators={{
        isValidCreate: isValidMatch,
      }}
      columns={columns}
      getNewRow={getNewRow}
      getDeleteDialog={getDeleteDialog}
      unableToDeleteTooltip="The match can not be deleted while associated with a match."
      itemName="match"
      lockKey="max_matches"
      initialSortModel={[{ field: "start_time", sort: "desc" }]}
      fieldToFocus="home_team_id"
    />
  );
};
