import { Action, DatabaseAction, ExpandedAction } from "../Action";
import {
  DetailedEvent,
  Match,
  Team,
} from "../../../requests/utils/databaseTypes";
import { Dispatch, SetStateAction } from "react";
import { PossessionChange } from "../../../utils/actionMappings";
import { PostMatchEventResponse } from "../../../requests/sendPostMatchEvent";

export abstract class ActionConfig {
  readonly action: Action;
  readonly nextActions: DatabaseAction[];
  readonly possessionChange?: PossessionChange;
  readonly getRequiresPitchClick: (previousEvents: DetailedEvent[]) => boolean;
  abstract getClickPhrase: (actionTypes: Record<Action, string>) => string;

  protected constructor(
    action: Action,
    nextActions: DatabaseAction[],
    getRequiresPitchClick: (previousEvents: DetailedEvent[]) => boolean,
    possessionChange?: PossessionChange
  ) {
    this.action = action;
    this.nextActions = [...nextActions, DatabaseAction.penaltyWon];
    this.getRequiresPitchClick = getRequiresPitchClick;
    this.possessionChange = possessionChange;
  }

  // getMostRecentNonFlagEvent() must not be an arrow function because we call it with super.getMostRecentNonFlagEvent().
  abstract getIsHomeTeamPossession: (
    previousEvents: DetailedEvent[],
    match: Match,
    isPossessionChangePseudoEventAction: boolean
  ) => boolean;

  // isValid() must not be an arrow function because we call it with super.isValid().
  abstract isValid(
    previousEvents: DetailedEvent[],
    pseudoEventActions: Set<ExpandedAction>,
    actionConfigs: Record<Action, ActionConfig>
  ): boolean;

  abstract doAction: (
    previousEvents: DetailedEvent[],
    createEvent: (
      action: DatabaseAction,
      possessionTeamId: number,
      locX: number,
      locY: number
    ) => Promise<PostMatchEventResponse>,
    pseudoEventActions: Set<ExpandedAction>,
    setPseudoEventActions: Dispatch<SetStateAction<Set<ExpandedAction>>>,
    match: Match,
    locX?: number,
    locY?: number
  ) => Promise<void>;

  getButtonPhrase = (
    actionTypes: Record<Action, string>,
    _homeTeam: Team,
    _awayTeam: Team
  ) => this.getClickPhrase(actionTypes);
}
