import React, { useState } from "react";
import { Hero } from "../components/Hero";
import { Button, TextField, Tooltip } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useUser } from "../requests/sendGetUsers";
import { useCreateOrganization } from "../requests/sendPostOrganization";
import { CustomSpinner } from "../components/CustomSpinner";

export const NewUser = () => {
  const user = useUser();

  if (!user) {
    return <CustomSpinner />;
  }

  return (
    <div style={{ textAlign: "center" }}>
      {user?.organization_id === undefined || user?.organization_id === null ? (
        <NewUserNotInOrg />
      ) : (
        <NewUserInOrg />
      )}
    </div>
  );
};

const NewUserInOrg = () => {
  const user = useUser();
  const navigate = useNavigate();
  return (
    <>
      <Hero
        title={user?.organization_name?.toString()}
        text={[
          `Welcome to QuickTap, ${user?.name}!`,
          "- - - - -",
          "You have already been added to the team by the administrator.",
        ]}
      />
      <Button
        sx={{ justifySelf: "center" }}
        variant="contained"
        size="large"
        onClick={() => navigate("/")}
      >
        Continue
      </Button>
    </>
  );
};

const NewUserNotInOrg = () => {
  const navigate = useNavigate();
  const createOrganizationQuery = useCreateOrganization();

  const [yourName, setYourName] = useState("");
  const [organizationName, setOrganizationName] = useState("");
  const isFormComplete = !!yourName && !!organizationName;

  if (createOrganizationQuery.isLoading) {
    return <CustomSpinner />;
  }

  return (
    <>
      <Hero
        title="Welcome to QuickTap!"
        text={[
          "If someone on your team has already signed up for QuickTap, request that the administrator add your email address to the users.",
          "- - - - -",
          "If you are the team's first QuickTap user, sign up below!",
        ]}
      />
      <TextField
        value={yourName}
        onChange={(e) => setYourName(e.target.value)}
        label="Your Name"
        sx={{ m: 1, mt: -4 }}
      />
      <TextField
        value={organizationName}
        onChange={(e) => setOrganizationName(e.target.value)}
        label="Team Name"
        sx={{ m: 1, mt: -4 }}
      />
      <br />
      <Tooltip
        disableHoverListener={isFormComplete}
        title="Add your name and the team name to sign up!"
        followCursor={true}
      >
        <span>
          <Button
            variant="contained"
            size="large"
            disabled={!isFormComplete || createOrganizationQuery.isLoading}
            onClick={() =>
              createOrganizationQuery.mutate(
                {
                  organization_name: organizationName,
                  user_name: yourName,
                },
                { onSuccess: () => navigate("/") }
              )
            }
          >
            Sign Up
          </Button>
        </span>
      </Tooltip>
    </>
  );
};
