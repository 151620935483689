import { NoDatabaseActionActionConfig } from "./NoDatabaseActionActionConfig";
import { DetailedEvent, Match } from "../../../../requests/utils/databaseTypes";
import { DatabaseAction, ExpandedAction } from "../../Action";
import { Dispatch, SetStateAction } from "react";
import { addOrDeleteItemInSet } from "../../../../utils/addOrDeleteItemInSet";

export class OverrideActionConfig extends NoDatabaseActionActionConfig {
  constructor() {
    super(ExpandedAction.override);
  }

  getButtonPhrase = () => "Override";
  isValid = () => true;
  doAction = async (
    _previousEvents: DetailedEvent[],
    _createEvent: (
      action: DatabaseAction,
      possessionTeamId: number,
      locX: number,
      locY: number
    ) => void,
    _pseudoEventActions: Set<ExpandedAction>,
    setPseudoEventActions: Dispatch<SetStateAction<Set<ExpandedAction>>>,
    _match: Match,
    _locX?: number,
    _locY?: number
  ): Promise<void> =>
    setPseudoEventActions((prevState) =>
      addOrDeleteItemInSet(prevState, ExpandedAction.override)
    );
}
