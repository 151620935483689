import * as React from "react";
import { LARGE_COLUMN, XXL_COLUMN } from "../utils/columnSizes";
import {
  useCreateInternalUser,
  useDeleteUser,
  useGetUser,
  useUpdateUser,
  useUsers,
} from "../requests/sendGetUsers";
import { CustomSpinner } from "../components/CustomSpinner";
import { useMatches } from "../requests/sendGetMatches";
import { User, UserPermission } from "../requests/utils/databaseTypes";
import { isValidEmail } from "../utils/isValidEmail";
import { NewSelectorGridCol } from "../components/CrudTable/columns/SelectorGridCol";
import { MutableGridCol } from "../components/CrudTable/columns/MutableGridCol";
import { FullFeaturedCrudGrid } from "../components/CrudTable/FullFeaturedCrudGrid";
import { ConstGridCol } from "../components/CrudTable/columns/ConstGridCol";

export const Users = () => {
  const users = useUsers();
  const getUser = useGetUser();
  const createUser = useCreateInternalUser().mutateAsync;
  const updateUser = useUpdateUser().mutate;
  const deleteUser = useDeleteUser().mutate;

  const matches = useMatches();

  const isValidUser = (user?: User) =>
    !!user?.name?.trim()?.length &&
    user?.permission_level_id &&
    isValidEmail(user?.email);

  const columns = [
    new MutableGridCol("name", "Name", XXL_COLUMN),
    new NewSelectorGridCol<User>(
      "permission_level_id",
      "Permission",
      LARGE_COLUMN,
      Object.keys(UserPermission)
        .filter((key) => isNaN(Number(key)))
        .map((permission) => ({
          value: UserPermission[permission as keyof typeof UserPermission], // numeric representation
          label: permission, // string representation
        })),
      (params) => UserPermission[params.value]
    ),
    new ConstGridCol("email", "Email", XXL_COLUMN),
  ];

  if (!users || !matches) {
    return <CustomSpinner />;
  }

  const getNewRow = (id: number): User => ({
    id,
    name: "",
    permission_level_id: UserPermission.read,
    email: "",
  });

  const getDeleteDialog = (id: number | null) => {
    const user = getUser(id);
    return `Delete ${user?.name}?`;
  };

  return (
    <FullFeaturedCrudGrid
      crudRequests={{
        useCollection: useUsers,
        postSingleton: (user: User) => createUser(user),
        putSingleton: (user: User) => updateUser(user),
        deleteSingleton: (id: number) => deleteUser(id),
      }}
      validators={{
        isValidCreate: isValidUser,
      }}
      columns={columns}
      getNewRow={getNewRow}
      getDeleteDialog={getDeleteDialog}
      unableToDeleteTooltip="The user can not be deleted while associated with a match."
      itemName="user"
      lockKey="max_users"
      initialSortModel={[{ field: "name", sort: "asc" }]}
      fieldToFocus="name"
    />
  );
};
