import { HttpRequestMethod, send } from "./utils/send";

export const sendDeleteMatchEvents = async (
  matchId: number,
  matchEventIds: number[]
): Promise<boolean> =>
  send<boolean>(
    `/matches/${matchId}/events`,
    HttpRequestMethod.DELETE,
    matchEventIds
  );
