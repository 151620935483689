import { Button, ButtonGroup, Link, Stack } from "@mui/material";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useActionTypes } from "../requests/sendGetActionTypes";
import { useMatches } from "../requests/sendGetMatches";
import { useMatchTypes } from "../requests/sendGetMatchTypes";
import { useTags } from "../requests/sendGetTags";
import { useTeams } from "../requests/sendGetTeams";
import { useUser, useUsers } from "../requests/sendGetUsers";
import { CustomSpinner } from "../components/CustomSpinner";
import { LockableButton } from "../components/LockableButton";
import SportsRugby from "@mui/icons-material/SportsRugby";
import GroupsIcon from "@mui/icons-material/Groups";
import OndemandVideoIcon from "@mui/icons-material/OndemandVideo";
import InsightsIcon from "@mui/icons-material/Insights";
import { UserPermission } from "../requests/utils/databaseTypes";

// Each query must have `enabled` specified to be false if user does not exist.
const usePrefetchQueries = () => {
  useActionTypes();
  useMatches();
  useMatchTypes();
  useTeams();
  useTags();
  useUsers();
};

export const Home = () => {
  const user = useUser();
  const navigate = useNavigate();
  usePrefetchQueries();

  useEffect(() => {
    if (user && user.organization_id === undefined) {
      navigate("/new-user");
    }
  }, [user, navigate]);

  if (!user || user.organization_id === undefined) {
    return <CustomSpinner />;
  }

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        marginTop: "4em",
      }}
    >
      <Stack spacing={6}>
        <ButtonGroup
          orientation="vertical"
          variant="contained"
          sx={{ width: "fit-content", padding: 4 }}
        >
          <Stack spacing={2} direction="column">
            <Stack spacing={2} direction="row">
              <LockableButton
                onClick={() => navigate("/video-review")}
                lockMessage="Video Review requires a QuickTap Pro account."
                isLocked={!user.organization_permissions?.video_review_enabled}
                component="label"
                startIcon={<OndemandVideoIcon style={{ fontSize: "75px" }} />}
                style={{ width: "225px", height: "135px", fontSize: "25px" }}
                sx={{ flexDirection: "column" }}
              >
                Video Review
              </LockableButton>
              <LockableButton
                onClick={() => navigate("/analytics")}
                lockMessage="Analytics requires a QuickTap Pro account."
                isLocked={!user.organization_permissions?.video_review_enabled}
                component="label"
                startIcon={<InsightsIcon style={{ fontSize: "75px" }} />}
                style={{ width: "225px", height: "135px", fontSize: "25px" }}
                sx={{ flexDirection: "column" }}
              >
                Analytics
              </LockableButton>
            </Stack>
            <Stack spacing={2} direction="row">
              <LockableButton
                onClick={() => navigate("/matches")}
                component="label"
                startIcon={<SportsRugby style={{ fontSize: "75px" }} />}
                style={{ width: "225px", height: "135px", fontSize: "25px" }}
                sx={{ flexDirection: "column" }}
                minUserPermission={UserPermission.write}
              >
                Tracker
              </LockableButton>
              <Button
                onClick={() => navigate("/teams")}
                component="label"
                startIcon={<GroupsIcon style={{ fontSize: "75px" }} />}
                style={{ width: "225px", height: "135px", fontSize: "25px" }}
                sx={{ flexDirection: "column" }}
              >
                Teams
              </Button>
            </Stack>
          </Stack>
        </ButtonGroup>
        <ButtonGroup
          orientation="vertical"
          variant="contained"
          sx={{ width: "100%", padding: 4 }}
        >
          <Stack spacing={4}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Link href="/account">Manage My Account</Link>
              <Link
                href="https://getquicktap.com/docs"
                target="_blank"
                rel="noreferrer"
              >
                Read the Docs
              </Link>
              <Link href="mailto:support@getquicktap.com">
                Email Customer Support
              </Link>
            </div>
          </Stack>
        </ButtonGroup>
      </Stack>
    </div>
  );
};
