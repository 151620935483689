import assert from "assert";
import React from "react";
import {
  Action,
  AllDatabaseActions,
  DatabaseAction,
  ExpandedAction,
} from "../../business-logic/ActionBehavior/Action";
import { ActionButtonGroup } from "./ActionButtonGroup";
import { ColorPalette } from "../../utils/constants";
import { DetailedEvent, Team } from "../../requests/utils/databaseTypes";

type Props = {
  isActionButtonsEnabled: boolean;
  previousEvents: DetailedEvent[];
  pseudoEventActions: Set<ExpandedAction>;
  onActionButtonClick: (action: Action) => void;
  homeTeam: Team;
  awayTeam: Team;
};

export type ActionButtonConfig = {
  // action can be undefined so that we can have empty buttons to fill space.
  action?: Action;
  color: string;
};

export const ActionButtons = ({
  isActionButtonsEnabled,
  previousEvents,
  pseudoEventActions,
  onActionButtonClick,
  homeTeam,
  awayTeam,
}: Props) => {
  const column1: ActionButtonConfig[] = [
    { action: DatabaseAction.tackled, color: ColorPalette.purple },
    { action: ExpandedAction.tackledAndRuck, color: ColorPalette.purple },
    { action: DatabaseAction.ruck, color: ColorPalette.purple },
    { action: DatabaseAction.phase, color: ColorPalette.purple },
    { action: DatabaseAction.offload, color: ColorPalette.purple },
    { action: ExpandedAction.homeTeamKnockOn, color: ColorPalette.lightBlue },
    { action: ExpandedAction.awayTeamKnockOn, color: ColorPalette.lightBlue },
    { action: DatabaseAction.forwardPass, color: ColorPalette.lightBlue },
    { action: DatabaseAction.turnover, color: ColorPalette.lightBlue },
    { action: ExpandedAction.homeTeamPenalty, color: ColorPalette.red },
  ];

  const column2: ActionButtonConfig[] = [
    { action: DatabaseAction.kickoff, color: ColorPalette.maroon },
    { action: DatabaseAction.tapRestart, color: ColorPalette.maroon },
    { action: DatabaseAction.kickRestart, color: ColorPalette.maroon },
    { action: DatabaseAction.intoTouch, color: ColorPalette.orange },
    { action: DatabaseAction.lineout, color: ColorPalette.orange },
    { action: DatabaseAction.maulStart, color: ColorPalette.orange },
    { action: DatabaseAction.maulEnd, color: ColorPalette.orange },
    { action: DatabaseAction.scrum, color: ColorPalette.orange },
    { action: DatabaseAction.scrumReset, color: ColorPalette.orange },
    { action: ExpandedAction.awayTeamPenalty, color: ColorPalette.red },
  ];

  const column3: ActionButtonConfig[] = [
    { action: DatabaseAction.openPlayKick, color: ColorPalette.green },
    { action: DatabaseAction.chargedDownKick, color: ColorPalette.green },
    { action: DatabaseAction.receiveKick, color: ColorPalette.green },
    { action: DatabaseAction.recoverKick, color: ColorPalette.green },
    { action: DatabaseAction.mark, color: ColorPalette.green },
    { action: DatabaseAction.ballGrounded, color: ColorPalette.green },
    { color: ColorPalette.orange },
    { color: ColorPalette.orange },
    { color: ColorPalette.orange },
    { action: ExpandedAction.homeTeamFreeKick, color: ColorPalette.red },
  ];

  const column4: ActionButtonConfig[] = [
    { action: DatabaseAction.try, color: ColorPalette.magenta },
    { action: DatabaseAction.heldUpTry, color: ColorPalette.magenta },
    { action: DatabaseAction.conversionGoalKick, color: ColorPalette.magenta },
    { action: DatabaseAction.conversionKickMiss, color: ColorPalette.magenta },
    { action: DatabaseAction.penaltyGoalKick, color: ColorPalette.magenta },
    { action: DatabaseAction.dropGoalKick, color: ColorPalette.magenta },
    { action: DatabaseAction.goalKickMade, color: ColorPalette.magenta },
    { action: DatabaseAction.endOfHalf, color: ColorPalette.gray },
    { action: DatabaseAction.flag, color: ColorPalette.gray },
    { action: ExpandedAction.awayTeamFreeKick, color: ColorPalette.red },
  ];

  // TODO: make this an actual test
  const allButtonActions = column1
    .concat(column2, column3, column4)
    .map(({ action }) => action);

  const missingButtons = AllDatabaseActions.reduce((acc, action) => {
    if (
      !allButtonActions
        .concat([
          DatabaseAction.freeKickWon,
          DatabaseAction.knockOn,
          DatabaseAction.penaltyWon,
        ])
        .includes(action)
    ) {
      acc.push(action);
    }
    return acc;
  }, [] as Action[]);
  assert(
    !missingButtons.length,
    `Missing action buttons: ${missingButtons
      .sort()
      .map((key) => DatabaseAction[key])}`
  );

  return (
    <section style={{ display: "flex", justifyContent: "center" }}>
      <ActionButtonGroup
        isActionButtonsEnabled={isActionButtonsEnabled}
        previousEvents={previousEvents}
        pseudoEventActions={pseudoEventActions}
        onActionButtonClick={onActionButtonClick}
        actionsInButtonGroup={column1}
        homeTeam={homeTeam}
        awayTeam={awayTeam}
      />
      <ActionButtonGroup
        isActionButtonsEnabled={isActionButtonsEnabled}
        previousEvents={previousEvents}
        pseudoEventActions={pseudoEventActions}
        onActionButtonClick={onActionButtonClick}
        actionsInButtonGroup={column2}
        homeTeam={homeTeam}
        awayTeam={awayTeam}
      />
      <ActionButtonGroup
        isActionButtonsEnabled={isActionButtonsEnabled}
        previousEvents={previousEvents}
        pseudoEventActions={pseudoEventActions}
        onActionButtonClick={onActionButtonClick}
        actionsInButtonGroup={column3}
        homeTeam={homeTeam}
        awayTeam={awayTeam}
      />
      <ActionButtonGroup
        isActionButtonsEnabled={isActionButtonsEnabled}
        previousEvents={previousEvents}
        pseudoEventActions={pseudoEventActions}
        onActionButtonClick={onActionButtonClick}
        actionsInButtonGroup={column4}
        homeTeam={homeTeam}
        awayTeam={awayTeam}
      />
    </section>
  );
};
