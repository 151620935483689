import {
  Action,
  DatabaseAction,
} from "../../business-logic/ActionBehavior/Action";

export type Team = {
  id: number;
  name: string;
  color: string;
};

export type MatchType = {
  id: number;
  name: string;
};

export type Match = {
  id: number;
  home_team_id: number | "";
  away_team_id: number | "";
  match_type_id: number | "";
  start_time: Date;
  location: string;
  video_url: string;
};

export type ActionType = {
  id: Action;
  name: string;
};

export type GtEvent = {
  // id: number;
  match_id: number;
  match_event_id: number;
  action_type_id: DatabaseAction;
  begin_team_id: number;
  end_team_id: number;
  event_time_in_seconds: number;
  loc_x: number;
  loc_y: number;
  is_deleted: boolean;
};

export type DetailedEvent = GtEvent & {
  comment?: string;
  lead_time_in_seconds?: number;
  tags: string[];
};

export type MetricEvent = {
  match_id: number;
  home_team: string;
  away_team: string;
  team: string;
  color: string;
  half_number: number;
  match_clock: string;
  action: string;
  x: number;
  y: number;
  possession: number;
  meters_gained: number;
  seconds_elapsed: number;
  points: number;
  tackles: number;
  penalties: number;
  gain_line: number;
  territory: number;
  inside22: number;
  phase: number;
  kick: number;
};

export type Tag = {
  id: number;
  name: string;
  // TODO: not actually optional, but we don't use it and don't set it when updating ReactQuery state
  organization_id?: number;
};

// This must be kept up-to-date with the database.
export enum UserPermission {
  owner = 1,
  write = 2,
  read = 3,
}

export type User = {
  id: number;
  // cognito_external_id?: string;
  organization_id?: number;
  permission_level_id?: UserPermission;
  name?: string;
  email: string;
};

// This must be kept up-to-date with the backend.
export type OrganizationPermissions = {
  max_matches?: number;
  max_users?: number;
  video_review_enabled: boolean;
};

export type UserResponse = User & {
  organization_name?: string;
  organization_permissions?: OrganizationPermissions | null;
  user_agreement_signed?: boolean;
};

export type PostTempIdResponse = {
  id: number;
  temp_id: number;
};
