import { NoDatabaseActionActionConfig } from "./NoDatabaseActionActionConfig";
import { DetailedEvent, Match } from "../../../../requests/utils/databaseTypes";
import { DatabaseAction, ExpandedAction } from "../../Action";
import { Dispatch, SetStateAction } from "react";
import { addOrDeleteItemInSet } from "../../../../utils/addOrDeleteItemInSet";

export class PossessionChangeActionConfig extends NoDatabaseActionActionConfig {
  constructor() {
    super(ExpandedAction.possessionChange);
  }

  getButtonPhrase = () => "Possession Change";

  isValid = (
    previousEvents: DetailedEvent[],
    pseudoEventActions: Set<ExpandedAction>
  ): boolean => {
    const mostRecentNonFlagEvent =
      this.getMostRecentNonFlagEvent(previousEvents);
    return (
      pseudoEventActions.has(ExpandedAction.override) ||
      (!!mostRecentNonFlagEvent &&
        [
          DatabaseAction.conversionGoalKick,
          DatabaseAction.conversionKickMiss,
          DatabaseAction.dropGoalKick,
          DatabaseAction.endOfHalf,
          DatabaseAction.ballGrounded,
          DatabaseAction.intoTouch,
          DatabaseAction.penaltyGoalKick,
        ].includes(mostRecentNonFlagEvent.action_type_id))
    );
  };
  doAction = async (
    _previousEvents: DetailedEvent[],
    _createEvent: (
      action: DatabaseAction,
      possessionTeamId: number,
      locX: number,
      locY: number
    ) => void,
    _pseudoEventActions: Set<ExpandedAction>,
    setPseudoEventActions: Dispatch<SetStateAction<Set<ExpandedAction>>>,
    _match: Match,
    _locX?: number,
    _locY?: number
  ): Promise<void> =>
    setPseudoEventActions((prevState) =>
      addOrDeleteItemInSet(prevState, ExpandedAction.possessionChange)
    );
}
