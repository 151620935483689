import { GtEvent } from "./utils/databaseTypes";
import { HttpRequestMethod, send } from "./utils/send";

export type PostMatchEventResponse = {
  match_id: number;
  match_event_id: number;
  temp_match_event_id: number;
};
export const sendPostMatchEvent = (
  event: GtEvent
): Promise<PostMatchEventResponse> =>
  send<PostMatchEventResponse>(
    `/matches/${event.match_id}/events`,
    HttpRequestMethod.POST,
    event
  ).then((response) => ({
    ...response,
    temp_match_event_id: event.match_event_id,
  }));
