import { useEventListener } from "usehooks-ts";
import { actionKeyboardShortcuts } from "../business-logic/ActionBehavior/actionKeyboardShortcuts";
import { VideoPlayerRef } from "../components/VideoPlayer";
import {
  Action,
  ExpandedAction,
} from "../business-logic/ActionBehavior/Action";
import { ACTION_CONFIGS } from "../utils/actionMappings";
import { DetailedEvent } from "../requests/utils/databaseTypes";

// Don't trigger keyboard shortcuts if typing in a text box.
const isTextBoxEvent = (e: KeyboardEvent) =>
  e?.target instanceof HTMLTextAreaElement ||
  e?.target instanceof HTMLInputElement;

export const useVideoPlayerKeyboardShortcuts = (video: VideoPlayerRef) =>
  useEventListener("keydown", (e: KeyboardEvent) => {
    if (isTextBoxEvent(e)) {
      return;
    }

    switch (e.key) {
      case " ":
        video?.toggle();
        // prevent spacebar from scrolling down
        e.preventDefault();
        break;
      case "ArrowLeft":
        video?.skipForward(-5);
        break;
      case "ArrowRight":
        video?.skipForward(5);
        break;
      case "/":
        video?.skipForward(30);
        break;
      case ".":
        video?.skipForward(-15);
        break;
    }
  });

export const useActionKeyboardShortcuts = (
  isActionButtonsEnabled: boolean,
  previousEvents: DetailedEvent[],
  pseudoEventActions: Set<ExpandedAction>,
  onActionButtonClick: (action: Action) => void,
  isSelectedAction: boolean,
  removeSelectedAction: () => void
) =>
  useEventListener("keydown", (e: KeyboardEvent) => {
    if (isTextBoxEvent(e)) {
      return;
    }

    if (e.key === "Escape" && isSelectedAction) {
      removeSelectedAction();
      return;
    }

    const action: Action | undefined = actionKeyboardShortcuts[e.key];
    if (
      isActionButtonsEnabled &&
      ACTION_CONFIGS[action]?.isValid(
        previousEvents,
        pseudoEventActions,
        ACTION_CONFIGS
      )
    ) {
      onActionButtonClick(action);
    }
  });
