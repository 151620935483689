import { ButtonGroup } from "@mui/material";
import React from "react";
import { useActionTypes } from "../../requests/sendGetActionTypes";
import {
  Action,
  ExpandedAction,
} from "../../business-logic/ActionBehavior/Action";

import { ColorButton } from "../ColorButton";
import { ACTION_CONFIGS } from "../../utils/actionMappings";
import { DetailedEvent, Team } from "../../requests/utils/databaseTypes";
import { getActionKeyboardShortcut } from "../../business-logic/ActionBehavior/actionKeyboardShortcuts";
import { ActionButtonConfig } from "./ActionButtons";

type Props = {
  isActionButtonsEnabled: boolean;
  previousEvents: DetailedEvent[];
  pseudoEventActions: Set<ExpandedAction>;
  onActionButtonClick: (action: Action) => void;
  actionsInButtonGroup: ActionButtonConfig[];
  homeTeam: Team;
  awayTeam: Team;
};

export const ActionButtonGroup = ({
  isActionButtonsEnabled,
  previousEvents,
  pseudoEventActions,
  onActionButtonClick,
  actionsInButtonGroup,
  homeTeam,
  awayTeam,
}: Props) => {
  const actionTypes = useActionTypes();

  return (
    <ButtonGroup orientation="vertical" sx={{ mt: 1 }}>
      {actionsInButtonGroup.map(({ action, color }, idx) => {
        const actionBehavior =
          action !== undefined ? ACTION_CONFIGS[action] : undefined;
        const keyboardShortcut =
          action !== undefined ? getActionKeyboardShortcut(action) : undefined;

        const actionPhrase =
          actionBehavior?.getButtonPhrase(actionTypes, homeTeam, awayTeam) ??
          ".";

        return (
          <ColorButton
            // `blank-${idx}` is necessary so that each item in list has a unique key
            key={action ?? `blank-${idx}`}
            onClick={
              action !== undefined
                ? () => onActionButtonClick(action)
                : undefined
            }
            disabled={
              (!isActionButtonsEnabled ||
                !actionBehavior?.isValid(
                  previousEvents,
                  pseudoEventActions,
                  ACTION_CONFIGS
                )) ??
              true
            }
            variant="contained"
            size="small"
            color={color}
            sx={{
              textTransform: "none",
              mx: 0.25,
              my: 0.5,
              fontSize: ".71vw",
            }}
          >
            <strong>
              {actionPhrase +
                (keyboardShortcut
                  ? ` (${keyboardShortcut.toUpperCase()})`
                  : "")}
            </strong>
          </ColorButton>
        );
      })}
    </ButtonGroup>
  );
};
