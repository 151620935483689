// get argument from url, post to server, and redirect
import { useNavigate } from "react-router-dom";
import { usePutHandleCheckout, useUser } from "../requests/sendGetUsers";
import { CustomSpinner } from "../components/CustomSpinner";

// This is intended to be a redirect endpoint from the Stripe checkout session to associate the customer with an org.
export const HandleCheckout = () => {
  const user = useUser();
  const navigate = useNavigate();
  const putHandleCheckout = usePutHandleCheckout();

  const urlSearchParams = new URLSearchParams(window.location.search);
  const checkoutSessionId = urlSearchParams.get("checkout_session_id") ?? "";

  // Wait for user to be defined before haphazardly redirecting.
  if (user === undefined) {
    return <CustomSpinner />;
  }

  if (user?.organization_permissions !== null) {
    navigate("/");
  }

  putHandleCheckout(checkoutSessionId).then(() => {
    navigate("/");
  });

  return <CustomSpinner />;
};
