import { EventGridRowModel } from "../event-grid/EventGrid";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { Chip, IconButton } from "@mui/material";
import React, { useState } from "react";
import { EventCard } from "./EventCard";
import ShareIcon from "@mui/icons-material/Share";
import { ShareUrlPopover } from "./ShareUrlPopover";

type Props = {
  eventRows: EventGridRowModel[];
  currentEventIndex: number | null;
  setCurrentEventIndex: (eventIndex: number) => void;
  pauseVideo: () => void;
};

export const EventCards = ({
  eventRows,
  currentEventIndex,
  setCurrentEventIndex,
  pauseVideo,
}: Props) => {
  const [sharePopover, setSharePopover] = useState<HTMLButtonElement | null>(
    null
  );

  return (
    <Grid container direction="column" sx={{ m: 1, ml: 0 }} maxHeight="85vh">
      {eventRows?.length !== 0 && (
        <>
          <Box
            sx={{
              position: "absolute",
              transform: "translateY(-110%)",
            }}
          >
            <Chip
              size="medium"
              variant="outlined"
              label={`Clip ${(currentEventIndex ?? 0) + 1} / ${
                eventRows.length
              }`}
            />
            <ShareUrlPopover
              sharePopover={sharePopover}
              setSharePopover={setSharePopover}
            />
            <IconButton
              onClick={(e) => setSharePopover(e.currentTarget)}
              color="info"
              sx={{ m: 1 }}
            >
              <ShareIcon />
            </IconButton>
          </Box>
          <Grid overflow="auto">
            {eventRows?.map((event, eventIndex) => {
              return (
                <EventCard
                  key={`${event.match_id},${event.match_event_id}`}
                  event={event}
                  eventIndex={eventIndex}
                  isActiveEvent={eventIndex === currentEventIndex}
                  setCurrentEventIndex={setCurrentEventIndex}
                  pauseVideo={pauseVideo}
                />
              );
            })}
          </Grid>
        </>
      )}
    </Grid>
  );
};
