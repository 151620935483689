import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { HttpRequestMethod, send } from "./utils/send";
import { PostTempIdResponse, User, UserResponse } from "./utils/databaseTypes";
import { getUseCreate } from "./utils/useCrud/getUseCreate";
import { getUseUpdate } from "./utils/useCrud/getUseUpdate";
import { getUseDelete } from "./utils/useCrud/getUseDelete";
import { sendPostUserAgreementSignature } from "./sendPostUserAgreementSignature";
import { sendPutHandleCheckout } from "./sendPutHandleCheckout";

const sendGetUser = (): Promise<UserResponse> =>
  send<UserResponse>("/users/self", HttpRequestMethod.GET);

const sendGetUsers = (): Promise<User[]> =>
  send<UserResponse[]>("/users", HttpRequestMethod.GET);

const sendPostInternalUser = (user: User): Promise<PostTempIdResponse> =>
  send<PostTempIdResponse>(
    "/users/internal",
    HttpRequestMethod.POST,
    user
  ).then((response) => ({
    ...response,
    temp_id: user.id,
  }));

const sendPostExternalUser = () =>
  send<number>("/users/external", HttpRequestMethod.POST);

const sendPutUser = (user: User): Promise<boolean> =>
  send<boolean>(`/users/${user.id}`, HttpRequestMethod.PUT, user);

const sendDeleteUser = (id: number): Promise<boolean> =>
  send<boolean>(`/users/${id}`, HttpRequestMethod.DELETE);

export const useCreateExternalUser = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: sendPostExternalUser,
    onSuccess: (response) => {
      if (!response) {
        alert(
          "Error creating external user. Please refresh and try again or contact support."
        );
        return;
      }

      // We could mutate the user ourselves via onMutate, but it is simpler and reduces the opportunity for bugs to
      // invalidate and re-fetch the user.
      return queryClient.invalidateQueries(["user", "external"]);
    },
  });
};

const useUserQuery = () =>
  useQuery(["user"], sendGetUser, { staleTime: 5 * 60 * 1000 /* 5 minutes */ });

// TODO: pay attention to cache here, probably need to clear it on logout or other places like org creation
export const useUser = (): UserResponse | undefined => useUserQuery()?.data;

export const useSignUserAgreement = (): (() => Promise<void>) => {
  const userQuery = useUserQuery();
  return () =>
    sendPostUserAgreementSignature()
      // We must re-fetch because user.permissions will already be populated as null, and we don't manually fetch the user.
      .then(() => userQuery.refetch())
      .then(() => {});
};

export const usePutHandleCheckout = (): ((
  checkoutSessionId: string
) => Promise<void>) => {
  const userQuery = useUserQuery();
  return (checkoutSessionId: string) =>
    sendPutHandleCheckout(checkoutSessionId)
      // We must re-fetch because user.permissions will already be populated as null, and we don't manually fetch the user.
      .then(() => userQuery.refetch())
      .then(() => {});
};

const useUsersQuery = () => {
  const user = useUser();
  return useQuery(["users"], sendGetUsers, {
    enabled: user?.permission_level_id !== undefined,
  });
};

export const useUsers = (): UserResponse[] | undefined => useUsersQuery()?.data;

export const useGetUser = (): ((id: number | null) => User | undefined) => {
  const users = useUsers();
  return (id: number | null) => users?.find((user) => user.id === id);
};

export const useCreateInternalUser = getUseCreate<User>(
  ["users"],
  sendPostInternalUser
);

export const useUpdateUser = getUseUpdate(["users"], sendPutUser);

export const useDeleteUser = getUseDelete(["users"], sendDeleteUser);
