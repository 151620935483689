import React, { RefObject } from "react";
import { useEventListener } from "usehooks-ts";
import { useWaitForRef } from "../../hooks/useWaitForRef";
import { GtEvent } from "../../requests/utils/databaseTypes";
import pitch from "../rugbyPitch";

const CIRCUMFERENCE_IN_PIXELS = 12;

type Props = {
  color: string;
  currentEvent: GtEvent;
  pitchRef: RefObject<HTMLImageElement>;
};

type Dimensions = {
  width: number;
  height: number;
};

export const Dot = ({ color, currentEvent, pitchRef }: Props) => {
  const [dimensions, setDimensions] = React.useState<Dimensions>();

  useWaitForRef(
    pitchRef,
    (pitchRefCurrent) => {
      setDimensions({
        width: pitchRefCurrent.offsetWidth,
        height: pitchRefCurrent.offsetHeight,
      });
    },
    (pitchRefCurrent) =>
      !!(pitchRefCurrent.offsetWidth && pitchRefCurrent.offsetHeight)
  );

  // Rerender the dot when the size of the pitch changes.
  useEventListener("resize", () => {
    if (!pitchRef.current) {
      return;
    }

    setDimensions({
      width: pitchRef.current.offsetWidth,
      height: pitchRef.current.offsetHeight,
    });
  });

  if (!dimensions) {
    return null;
  }

  const left =
    pitch.getPixelXCoordinate(currentEvent.loc_x, dimensions.width) -
    CIRCUMFERENCE_IN_PIXELS / 2;
  const bottom =
    pitch.getPixelYCoordinate(currentEvent.loc_y, dimensions.height) -
    CIRCUMFERENCE_IN_PIXELS / 2;
  return (
    <span
      style={{
        height: CIRCUMFERENCE_IN_PIXELS,
        width: CIRCUMFERENCE_IN_PIXELS,
        backgroundColor: color,
        borderRadius: "50%",
        pointerEvents: "none",
        position: "absolute",
        left,
        bottom,
      }}
    />
  );
};
