import React, { ReactElement } from "react";
import { EventGridRowModel } from "../../components/event-grid/EventGrid";
import { TagsInput } from "../../components/TagsInput";
import { DetailedEvent } from "../../requests/utils/databaseTypes";

export const useTagsColumn = (
  activeEvent: DetailedEvent | null,
  pauseVideo: () => void
): ((row: EventGridRowModel) => ReactElement) => {
  return (row) => (
    <TagsInput
      event={row}
      isActiveEvent={
        activeEvent?.match_id === row.match_id &&
        activeEvent?.match_event_id === row.match_event_id
      }
      pauseVideo={pauseVideo}
    />
  );
};
